import React, { CSSProperties, useMemo, useReducer, useEffect } from "react";
import { useIntl } from "react-intl";
import { loadStripe } from "@stripe/stripe-js";
import {
    IonButton,
    IonModal,
    IonRow,
    IonCol,
    IonIcon,
    IonLoading,
    IonAlert,
    IonInput,
    IonSpinner,
    IonLabel,
    IonSelect,
    IonSelectOption,
    IonRadioGroup,
    IonItem,
    IonRadio,
    IonBadge,
} from "@ionic/react";
import { close, informationCircleOutline } from "ionicons/icons";
import { useAuthState } from "react-firebase-hooks/auth";
import { PurchaseTypes } from "@mail-per/common";
import { useMailboxOperations } from "../hooks/use-mailbox-operations";
import { useFirebase } from "../providers/firebase-provider";
import { usePurchaseCreate } from "../hooks/use-purchase-create";
import { usePackages } from "../hooks/use-packages";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY as string);

enum MailboxCreateModalActions {
    reset,
    set,
}

interface IMailboxCreateModalActionsState {
    email: string;
    domain: string;
    expiration: string;
    userError: string;
    premium: boolean;
    loading: boolean;
}

const initialState: IMailboxCreateModalActionsState = {
    email: "",
    domain: "@mailper.com",
    expiration: "6",
    userError: "",
    premium: false,
    loading: false,
};

const reducer = (state: any, action: any) => {
    switch (action.type) {
        case MailboxCreateModalActions.reset:
            return initialState;
        case MailboxCreateModalActions.set:
            const newState = {
                ...state,
                ...action.payload,
            };
            return {
                ...newState,
                premium: Number(newState.expiration) > 24,
            };
        default:
            return state;
    }
};

interface MailboxCreateModalProps {
    onClose: Function;
    open: boolean;
    style?: CSSProperties;
}

const MailboxCreateModal: React.FC<MailboxCreateModalProps> = ({ onClose, open, style = {} }) => {
    const {
        check,
        create,
        state: { createLoading, createError, checkLoading, checkError, checkSuccess },
    } = useMailboxOperations();
    const {
        purchase,
        state: { loading: purchaseLoading, error: purchaseError },
    } = usePurchaseCreate();

    const { auth } = useFirebase();
    const [user] = useAuthState(auth);

    const { formatMessage: t, locale } = useIntl();

    const [{ email, domain, expiration, premium, userError }, dispatch] = useReducer(
        reducer,
        initialState
    );
    const { create: packages } = usePackages();

    const error = useMemo(() => {
        return userError || createError || purchaseError;
    }, [userError, createError, purchaseError]);

    const onDismiss = () => {
        dispatch({
            type: MailboxCreateModalActions.reset,
        });
    };

    const onAction = async () => {
        if (premium && user?.isAnonymous) {
            return dispatch({
                type: MailboxCreateModalActions.set,
                payload: {
                    userError: t({ id: "mailboxSignInRequired" }),
                },
            });
        }
        if (premium && user) {
            const stripe = await stripePromise;
            const purchaseId = await purchase(
                email + domain,
                Number(expiration),
                PurchaseTypes.create
            );
            if (stripe && purchaseId) {
                stripe.redirectToCheckout({
                    items: [{ sku: packages[expiration].sku, quantity: 1 }],
                    customerEmail: user?.email as string,
                    clientReferenceId: purchaseId,
                    successUrl: `${process.env.REACT_APP_URL}/purchase/${purchaseId}`,
                    cancelUrl: `${process.env.REACT_APP_URL}`,
                    locale: locale as any,
                });
            }
            return;
        }
        const mailbox = await create(email + domain, Number(expiration));
        if (mailbox) {
            onDismiss();
            onClose();
        }
    };

    const onChange = (data: any) => {
        dispatch({
            type: MailboxCreateModalActions.set,
            payload: {
                ...data,
            },
        });
    };

    useEffect(() => {
        check(email, domain);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [email]);

    return (
        <IonModal
            isOpen={open}
            backdropDismiss={false}
            onDidDismiss={onDismiss}
            cssClass="ion-modal-scrollable"
        >
            <IonRow className="ion-align-items-center ion-padding-start">
                <IonCol>
                    <h5>{t({ id: "mailboxNew" })}</h5>
                </IonCol>
                <IonCol className="ion-text-end">
                    <IonButton fill="clear" onClick={onClose as any}>
                        <IonIcon slot="icon-only" icon={close} />
                    </IonButton>
                </IonCol>
            </IonRow>

            <div className="ion-padding">
                {user?.isAnonymous && (
                    <IonItem
                        lines="none"
                        className="ion-margin-bottom ion-letter-spacing-1"
                        style={{ color: "gray", fontSize: 14 }}
                    >
                        <IonIcon color="primary" icon={informationCircleOutline} slot="start" />
                        {t({ id: "mailboxDisclaimer" })}
                    </IonItem>
                )}
                <IonRow style={{ width: "100%" }}>
                    <IonCol size="4" className="ion-text-end">
                        {checkLoading && (
                            <IonSpinner
                                color="primary"
                                style={{
                                    display: "block",
                                    width: 16,
                                    position: "fixed",
                                    marginTop: 6,
                                }}
                            />
                        )}
                        <IonInput
                            autocorrect="off"
                            autocomplete="off"
                            required
                            debounce={1000}
                            placeholder={t({ id: "mailboxInsertEmail" })}
                            style={{
                                borderBottom: "2px solid #eb445a",
                                marginTop: 2,
                            }}
                            onIonChange={(e) => {
                                onChange({ email: e.detail.value! });
                            }}
                        />
                        {checkError && (
                            <IonLabel color="danger" style={{ fontSize: 10 }}>
                                {checkError}
                            </IonLabel>
                        )}
                    </IonCol>
                    <IonCol size="8">
                        <IonSelect
                            value={domain}
                            placeholder={t({
                                id: "mailboxSelectDomain",
                            })}
                            style={{
                                fontWeight: "bold",
                                color: "#eb445a",
                                letterSpacing: 1,
                                borderBottom: "2px solid #bfbebe",
                            }}
                        >
                            <IonSelectOption value="@mailper.com">@mailper.com</IonSelectOption>
                        </IonSelect>
                    </IonCol>
                </IonRow>
                <IonRow style={{ width: "100%" }} className="ion-align-items-center">
                    <IonCol size="4" className="ion-text-end">
                        <IonLabel>{t({ id: "expiration" })}</IonLabel>
                    </IonCol>
                    <IonCol size="8">
                        <IonRadioGroup
                            value={expiration}
                            onIonChange={(e) => onChange({ expiration: e.detail.value })}
                        >
                            {Object.keys(packages).map((key) => {
                                const item = packages[key];
                                return (
                                    <IonItem key={key} lines="none">
                                        <IonLabel color={item.price ? "primary" : undefined}>
                                            {item.description}
                                        </IonLabel>
                                        <IonRadio slot="start" value={key} />
                                        <IonBadge color={item.price ? "primary" : "light"}>
                                            {item.price ? `$${item.price}` : t({ id: "free" })}
                                        </IonBadge>
                                    </IonItem>
                                );
                            })}
                        </IonRadioGroup>
                    </IonCol>
                </IonRow>
            </div>

            <div className="ion-padding ion-text-end">
                <IonButton onClick={() => onAction()} disabled={!checkSuccess || checkLoading}>
                    {premium ? t({ id: "checkout" }) : t({ id: "mailboxCreate" })}
                </IonButton>
            </div>

            <IonLoading
                message={t({ id: "mailboxCreating" })}
                isOpen={createLoading || purchaseLoading}
            />
            <IonAlert
                isOpen={!!error}
                header={"Error"}
                message={error!}
                buttons={["OK"]}
                onDidDismiss={() =>
                    dispatch({
                        type: MailboxCreateModalActions.set,
                        payload: {
                            userError: "",
                        },
                    })
                }
            />
        </IonModal>
    );
};

export default MailboxCreateModal;
