import moment from "moment";
import React, { useEffect, useState } from "react";
import {
    IonRow,
    IonCol,
    IonProgressBar,
    IonList,
    IonItem,
    IonLabel,
    IonButton,
    IonModal,
    IonIcon,
    IonItemDivider,
    IonText,
} from "@ionic/react";
import { close } from "ionicons/icons";
import { IMailbox, MailperCommonFirebase } from "@mail-per/common";
import { useFirebase } from "../providers/firebase-provider";
import TextDate from "./text-date";

interface IStatsUserModal {
    userId: string | undefined;
    setUserId: (userId: string | undefined) => void;
    setMailboxId: (mailboxId: string | undefined) => void;
}

const StatsUserModal: React.FC<IStatsUserModal> = ({ userId, setUserId, setMailboxId }) => {
    const { firestore } = useFirebase();
    const [loading, setLoading] = useState(false);
    const [mailboxes, setMailboxes] = useState<IMailbox[]>([]);
    useEffect(() => {
        let unsubscribe: any;
        if (unsubscribe) {
            unsubscribe();
        }
        if (userId) {
            setLoading(true);
            unsubscribe = firestore
                .collection("mailboxes")
                .where("user", "==", userId)
                .onSnapshot(
                    (querySnapshot) => {
                        if (querySnapshot.empty) {
                            return setMailboxes([]);
                        }
                        setMailboxes(
                            querySnapshot.docs
                                .map((doc) => MailperCommonFirebase.documentSnapshotToMailbox(doc))
                                // @ts-ignore
                                .sort((a, b) => b.createdAt - a.createdAt)
                        );
                        setLoading(false);
                    },
                    (e) => {
                        setLoading(false);
                        setMailboxes([]);
                    }
                );
        }
        return () => {
            if (unsubscribe) {
                unsubscribe();
            }
        };
    }, [firestore, userId]);
    return (
        <IonModal isOpen={!!userId} backdropDismiss={false} cssClass="ion-modal-scrollable">
            <div>
                <IonRow className="ion-align-items-center ion-padding-start">
                    <IonCol>{userId}</IonCol>
                    <IonCol className="ion-text-end">
                        <IonButton fill="clear" onClick={() => setUserId(undefined)}>
                            <IonIcon slot="icon-only" icon={close} />
                        </IonButton>
                    </IonCol>
                </IonRow>
                {loading ? (
                    <IonProgressBar type="indeterminate"></IonProgressBar>
                ) : (
                    <IonList>
                        <IonItemDivider>
                            <IonLabel>Mailboxes</IonLabel>
                        </IonItemDivider>
                        {mailboxes.map((mailbox, index) => (
                            <IonItem
                                key={mailbox.id}
                                button
                                onClick={() => {
                                    setUserId(undefined);
                                    setMailboxId(mailbox.id);
                                }}
                            >
                                <IonText slot="start">#{index + 1}</IonText>
                                <IonLabel>
                                    <IonText color="primary">{mailbox.email}</IonText>
                                    <p> Expire {moment(mailbox.expireAt).fromNow()}</p>
                                </IonLabel>
                                <IonText slot="end">
                                    <TextDate date={mailbox.expireAt} />
                                </IonText>
                            </IonItem>
                        ))}
                    </IonList>
                )}
            </div>
        </IonModal>
    );
};

export default StatsUserModal;
