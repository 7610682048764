import React, { CSSProperties, useEffect } from "react";
import { useIntl } from "react-intl";
import * as firebase from "firebase/app";
import { IonButton, IonIcon, IonItem, IonList, IonModal, IonRow, IonCol } from "@ionic/react";
import { useLocation } from "react-router-dom";
import { close, logoGoogle, logoApple, logoGithub, logoFacebook } from "ionicons/icons";
import { useFirebase } from "../providers/firebase-provider";
import { useAuthState } from "react-firebase-hooks/auth";

interface SignInModalProps {
    onClose: Function;
    open: boolean;
    changeAccount: boolean;
    style?: CSSProperties;
}

const SignInModal: React.FC<SignInModalProps> = ({ open, onClose, changeAccount }) => {
    const { auth } = useFirebase();
    const [user] = useAuthState(auth);
    const location = useLocation();
    const { formatMessage: t } = useIntl();

    useEffect(() => {
        onClose();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location]);

    const onSignIn = async (provider: string) => {
        if (changeAccount) {
            onSignInAccount(provider);
        } else {
            onLinkAccount(provider);
        }
    };

    const onSignInAccount = async (provider: string) => {
        try {
            switch (provider) {
                case "google":
                    const googleProvider = new firebase.auth.GoogleAuthProvider();
                    await auth.signInWithRedirect(googleProvider);
                    break;
                case "github":
                    const githubProvider = new firebase.auth.GithubAuthProvider();
                    await auth.signInWithRedirect(githubProvider);
                    break;
                case "facebook":
                    const facebookProvider = new firebase.auth.FacebookAuthProvider();
                    await auth.signInWithRedirect(facebookProvider);
                    break;
                case "apple":
                    const appleProvider = new firebase.auth.OAuthProvider("apple.com");
                    await auth.signInWithRedirect(appleProvider);
                    break;
                case "microsoft":
                    const microsoftProvider = new firebase.auth.OAuthProvider("microsoft.com");
                    microsoftProvider.setCustomParameters({
                        tenant: process.env.REACT_APP_MICROSOFT_TENTANT,
                    });
                    microsoftProvider.addScope("mail.read");
                    await auth.signInWithRedirect(microsoftProvider);
                    break;
            }
            // Reload screen
            // to refresh connected user
            // window.location.reload();
        } catch {}
    };

    const onLinkAccount = async (provider: string) => {
        try {
            switch (provider) {
                case "google":
                    const googleProvider = new firebase.auth.GoogleAuthProvider();
                    await user?.linkWithRedirect(googleProvider);
                    break;
                case "github":
                    const githubProvider = new firebase.auth.GithubAuthProvider();
                    await user?.linkWithRedirect(githubProvider);
                    break;
                case "facebook":
                    const facebookProvider = new firebase.auth.FacebookAuthProvider();
                    await user?.linkWithRedirect(facebookProvider);
                    break;
                case "apple":
                    const appleProvider = new firebase.auth.OAuthProvider("apple.com");
                    await user?.linkWithRedirect(appleProvider);
                    break;
                case "microsoft":
                    const microsoftProvider = new firebase.auth.OAuthProvider("microsoft.com");
                    microsoftProvider.setCustomParameters({
                        tenant: process.env.REACT_APP_MICROSOFT_TENTANT,
                    });
                    microsoftProvider.addScope("mail.read");
                    await user?.linkWithRedirect(microsoftProvider);
                    break;
            }
            // Reload screen
            // to refresh connected user
            // window.location.reload();
        } catch (e) {
            if (e.code === "auth/credential-already-in-use") {
                try {
                    await auth.signInWithCredential(e.credential);
                    // Reload screen
                    // to refresh connected user
                    window.location.reload();
                } catch {}
            }
        }
    };

    return (
        <IonModal isOpen={open} backdropDismiss={false} cssClass="ion-modal-scrollable">
            <IonRow className="ion-align-items-center ion-padding-start">
                <IonCol></IonCol>
                <IonCol className="ion-text-end">
                    <IonButton fill="clear" onClick={() => onClose()}>
                        <IonIcon slot="icon-only" icon={close} />
                    </IonButton>
                </IonCol>
            </IonRow>
            <p
                style={{
                    margin: "0 32px 0 32px",
                    letterSpacing: 1,
                    color: "gray",
                }}
                className="ion-text-center"
            >
                {t({ id: "signInSubtitle" })}
            </p>
            <IonList lines="none" style={{ margin: "auto" }}>
                <IonItem detail={false} button onClick={() => onSignIn("google")}>
                    <IonIcon icon={logoGoogle} style={{ marginRight: 8 }} />
                    <span style={{ fontSize: 18 }}>Sign in with Google</span>
                </IonItem>
                <IonItem detail={false} button onClick={() => onSignIn("apple")}>
                    <IonIcon icon={logoApple} style={{ marginRight: 8 }} />
                    <span style={{ fontSize: 18 }}>Sign in with Apple</span>
                </IonItem>
                <IonItem detail={false} button onClick={() => onSignIn("github")}>
                    <IonIcon icon={logoGithub} style={{ marginRight: 8 }} />
                    <span style={{ fontSize: 18 }}>Sign in with Github</span>
                </IonItem>
                <IonItem detail={false} button onClick={() => onSignIn("facebook")}>
                    <IonIcon icon={logoFacebook} style={{ marginRight: 8 }} />
                    <span style={{ fontSize: 18 }}>Sign in with Facebook</span>
                </IonItem>
            </IonList>
            <div style={{ marginBottom: 32 }}></div>
        </IonModal>
    );
};

export default SignInModal;
