import React from "react";
import { IonAlert } from "@ionic/react";

interface IStatsFindUserAlert {
    isOpen: boolean;
    onDismiss: () => void;
    setUserId: (userId: string | undefined) => void;
}

const StatsFindUserAlert: React.FC<IStatsFindUserAlert> = ({ isOpen, onDismiss, setUserId }) => {
    return (
        <IonAlert
            isOpen={isOpen}
            onDidDismiss={() => onDismiss()}
            header={"Find User"}
            inputs={[
                {
                    name: "user",
                    type: "text",
                    placeholder: "User ID",
                },
            ]}
            buttons={[
                {
                    text: "Cancel",
                    role: "cancel",
                },
                {
                    text: "Find",
                    handler: ({ user }) => setUserId(user),
                },
            ]}
        />
    );
};

export default StatsFindUserAlert;
