import {
    IonContent,
    IonHeader,
    IonPage,
    IonToolbar,
    IonButtons,
    IonBackButton,
    IonProgressBar,
} from "@ionic/react";
import React, { useEffect } from "react";
import { RouteComponentProps } from "react-router";
import { useMailboxMessage } from "../hooks/use-mailbox-message";
import MessageContent from "../components/message-content";

interface MailboxPageProps
    extends RouteComponentProps<{
        mailbox: string;
        message: string;
    }> {}

const MessagePage: React.FC<MailboxPageProps> = ({ match, history }) => {
    const { loading, message } = useMailboxMessage(match.params.mailbox, match.params.message);
    useEffect(() => {
        if (!loading && !message) {
            history.replace("/");
        }
    }, [history, loading, message]);
    return (
        <IonPage>
            <IonHeader className="no-borders">
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonBackButton defaultHref={"/mailbox/" + match.params.mailbox} />
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                {loading || !message ? (
                    <IonProgressBar type="indeterminate" />
                ) : (
                    <MessageContent message={message} />
                )}
            </IonContent>
        </IonPage>
    );
};

export default MessagePage;
