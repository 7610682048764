export default {
  yes: "Si",
  no: "No",
  hours: "horas",
  day: "día",
  days: "días",
  week: "semana",
  month: "month",
  year: "año",
  next: "Siguiente",
  free: "Gratis",
  subtotal: "Subtotal",
  payment: "Pago",
  recover: "Recuperar",
  home: "Inicio",
  name: "Nombre",
  cancel: "Cancelar",
  email: "Email",
  checkout: "Pagar",
  expire: "Expira",
  expiration: "Expira",
  expired: "Expirados",
  confirmation: "Confirmación",
  mailbox: "Buzón",
  mailboxes: "Buzones",
  attachment: "Adjunto",
  attachments: "Adjuntos",
  languageEN: "Inglés",
  languageES: "Español",
  homeSlogan: "Buzones desechables",
  homeEmptyTitle: "Crea tu primer buzón",
  homeEmptySubtitle: "para comenzar a recibir mensajes",
  homeAddMailbox: "Agregar Buzón",
  homeSlide1Section1:
    "Puedes crear un buzón en menos de 30 segundos, siguiendo tres pasos:",
  homeSlide1Section2: "Presione",
  homeSlide1Section3: "Inserte el nombre de usuario para el email.",
  homeSlide1Section4: "Seleccione el tiempo de expiración",
  homeSlide2Section1:
    "Esperamos que disfrute Mailper; déjenos saber si tiene alguna sugerencia acerca del producto.",
  homeSlide2Section2: "Puedes contactárnos usando el email",
  signIn: "Iniciar Sessión",
  signOut: "Terminar Sessión",
  changeAccount: "Cambiar cuenta",
  signInSubtitle:
    "Mailper es completamente anónimo, pero en caso de necesitar acceder a los buzones desde otro dispositivo, debes iniciar sessión.",
  signInDisclaimer:
    "Su cuenta social puede ser usada para recuperar y acceder a sus buzones.",
  mailboxEmptyTitle: "Este buzón está vacio",
  mailboxEmptySubtitle: "envía correos electrónicos a ",
  mailboxExists: "El buzón {email} no esta disponible.",
  mailboxNew: "Nuevo buzón",
  mailboxInsertEmail: "correo",
  mailboxSelectDomain: "Seleccion el dominio",
  mailboxCreate: "Crear buzón",
  mailboxCreating: "Creando buzón",
  mailboxRecovering: "Recuperando buzón",
  mailboxSignInRequired:
    "Debes iniciar sesión antes de crear un buzón premium.",
  mailboxDisclaimer:
    "Aunque no es obligatorio, le recomendamos Iniciar Sesión para acceder a sus buzones desde otros dispositivos o recuperarlos después que caduquen.",
  mailboxesExpired: "Buzones expirados",
  purchaseReadyTitle: "Tu buzón está listo",
  purchaseReadySubtitle: "Puedes verlo en la página de inicio",
  purchasePendingTitle: "Tu buzón se está creando",
  purchasePendingSubtitle:
    "Espera unos segundos y verás el buzón en la página de inicio",
  expiredEmptyTitle: "Excelente",
  expiredEmptySubtitle: "No tienes buzones expirados",
  recoverFor: "Recuperar - {price}",
  recoverForMessage:
    "Deseas recuperar este buzón por un año por el precio de {price}?",
  recoverSubtitle: "Puedes recuperar tus buzones por {price}",
  privacy: "Privacidad",
  terms: "Términos",
  support: "Soporte",
  contactUsTitle: "Contáctanos",
  contactUsSubtitle: "Déjanos saber tus sugerencias",
  theme: "Tema",
  forward: "Reenviar",
  forwardSubtitle: "Reenvía mensajes a tu cuenta de correo personal.",
  forwardInputPlaceholder: "Tu cuenta de correo",
  forwardSkipTitle: "No Reenviar",
  forwardSkipSubTitle: "Bloquear reenvío",
  forwardAddEmail: "Nuevo correo",
  loading: "Procesando",
  saving: "Guardando",
  save: "Salvar",
  add: "Agregar",
  verify: "Verificar",
  verified: "Verificado",
  verification: "Verificación",
  verificationSubtitle: "Verifica tu correo para obtener el código.",
  code: "Código",
  addAddress: "Agregar Dirección",
  settings: "Ajustes",
  newVersion:
    "Importante! Una nueva versión de Mailper será lanzada en los próximos días, haga click aquí para ver el preview.",
};
