import { saveAs } from "file-saver";
import React, { useState } from "react";
import { useIntl } from "react-intl";
import { IMailboxMessage, IMailboxAttachment } from "@mail-per/common";
import { IonItem, IonText, IonItemDivider, IonSpinner } from "@ionic/react";
import { useFirebase } from "../providers/firebase-provider";

interface MessageAttachmentProps {
    message: IMailboxMessage;
    attachment: IMailboxAttachment;
    attachmentId: string;
}

const MessageAttachment: React.FC<MessageAttachmentProps> = ({
    message,
    attachment,
    attachmentId,
}) => {
    const { functions } = useFirebase();
    const [loading, setLoading] = useState(false);
    const [url, setURL] = useState<string>();

    const downloadFile = (signedURL: string) => {
        saveAs(signedURL, attachment.filename);
    };

    const onDownload = async () => {
        if (loading) {
            return;
        }
        if (url) {
            return downloadFile(url);
        }
        setLoading(true);
        const getDownloadURL = functions.httpsCallable("getDownloadURL");
        try {
            const result = await getDownloadURL({
                email: message.to,
                message: message.id,
                attachment: attachmentId,
            });
            const signedURL = result.data[0];
            downloadFile(signedURL);
            setLoading(false);
            setURL(signedURL);
        } catch (e) {
            setLoading(false);
        }
    };

    return (
        <div
            className="ion-margin-end ion-margin-bottom"
            style={{
                width: 128,
                height: 128,
                background: "#f5f2f2",
                borderRadius: 8,
            }}
        >
            <div
                className="attachment-box"
                style={{
                    fontSize: 14,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    textAlign: "center",
                    width: "100%",
                    height: "100%",
                    cursor: "pointer",
                    color: "gray",
                }}
                onClick={() => onDownload()}
            >
                {attachment.filename}
                {loading && (
                    <div style={{ position: "fixed" }}>
                        <IonSpinner />
                    </div>
                )}
            </div>
        </div>
    );
};

interface MessageAttachmentsProps {
    message: IMailboxMessage;
}

const MessageAttachments: React.FC<MessageAttachmentsProps> = ({ message }) => {
    const { formatMessage: t } = useIntl();
    if (!message.attachments) {
        return null;
    }
    return (
        <>
            <IonItemDivider />
            <IonItem lines="none" style={{ fontWeight: "bold", fontSize: 14 }}>
                <IonText>
                    {message.attachments}{" "}
                    {message.attachments > 1 ? t({ id: "attachments" }) : t({ id: "attachment" })}
                </IonText>
            </IonItem>
            <div className="ion-padding-start" style={{ display: "flex", flexWrap: "wrap" }}>
                {Object.keys(message.attachmentsInfo).map((key) => (
                    <MessageAttachment
                        key={key}
                        message={message}
                        attachment={message.attachmentsInfo[key]}
                        attachmentId={key}
                    />
                ))}
            </div>
        </>
    );
};

export default MessageAttachments;
