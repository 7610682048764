import React from "react";
import { useIntl } from "react-intl";
import { IonLabel } from "@ionic/react";

interface HomeEmptyProps {
    onClick: Function;
}

const HomeEmpty: React.FC<HomeEmptyProps> = ({ onClick }) => {
    const { formatMessage: t } = useIntl();
    return (
        <div className="ion-margin">
            <p
                style={{
                    margin: "64px 0 12px 0",
                    fontSize: 56,
                    fontWeight: "bold",
                }}
            >
                {t({ id: "homeEmptyTitle" })}
            </p>
            <p
                style={{
                    fontSize: 24,
                    color: "gray",
                    marginBottom: 64,
                }}
            >
                {t({ id: "homeEmptySubtitle" })}
            </p>
            <div className="ion-hide-lg-down ion-text-start" style={{ lineHeight: 2 }}>
                <p style={{ maxWidth: 360, color: "gray" }}>{t({ id: "homeSlide1Section1" })}</p>
                <ol>
                    <li>
                        {t({ id: "homeSlide1Section2" }) + " "}
                        <IonLabel
                            color="primary"
                            style={{ cursor: "pointer" }}
                            onClick={() => onClick()}
                        >
                            {t({ id: "homeAddMailbox" })}
                        </IonLabel>
                        .
                    </li>
                    <li>{t({ id: "homeSlide1Section3" })}</li>
                    <li>{t({ id: "homeSlide1Section4" })}</li>
                </ol>
            </div>
        </div>
    );
};

export default HomeEmpty;
