import moment from "moment";
import { useEffect, useReducer } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { IMailboxMessage, MailperCommonFirebase } from "@mail-per/common";
import { useFirebase } from "../providers/firebase-provider";

const LIMIT = 20;

enum UseMailboxMessagesActions {
    get,
    set,
    next,
    prev,
}

interface IUseMailboxMessagesState {
    id?: string;
    loading: boolean;
    messages: IMailboxMessage[];
    hasPrev: boolean;
    hasNext: boolean;
    prev: () => void;
    next: () => void;
    nextDate?: Date;
    prevPoints: Date[];
    startAt?: Date;
}

const initialState: IUseMailboxMessagesState = {
    id: undefined,
    loading: true,
    messages: [],
    hasPrev: false,
    hasNext: false,
    prev: () => {},
    next: () => {},
    nextDate: undefined,
    prevPoints: [],
    startAt: undefined,
};

const reducer = (state: any, action: any) => {
    switch (action.type) {
        case UseMailboxMessagesActions.get:
            return {
                ...state,
                loading: true,
            };
        case UseMailboxMessagesActions.set:
            const isSame = state.id === action.id;
            return {
                ...state,
                id: action.id,
                loading: false,
                messages: action.payload,
                nextDate: action.payload.length
                    ? action.payload[action.payload.length - 1].date
                    : undefined,
                prevPoints: isSame ? state.prevPoints : [],
                hasPrev: isSame ? state.prevPoints.length > 0 : false,
                hasNext: action.payload.length > 0 && !(action.payload.length % LIMIT),
            };
        case UseMailboxMessagesActions.next:
            state.prevPoints.push(
                state.prevPoints.length ? state.messages[0].date : moment().add(1, "year").toDate()
            );
            return {
                ...state,
                startAt: state.nextDate,
            };
        case UseMailboxMessagesActions.prev:
            const startAt = state.prevPoints.pop();
            return {
                ...state,
                startAt,
            };
        default:
            return state;
    }
};

export const useMailboxMessages = (mailboxId: string) => {
    const { auth, firestore } = useFirebase();
    const [user] = useAuthState(auth);
    const [state, dispatch] = useReducer(reducer, initialState);
    useEffect(() => {
        dispatch({ type: UseMailboxMessagesActions.get });
        if (!mailboxId) {
            return dispatch({
                type: UseMailboxMessagesActions.set,
                payload: [],
            });
        }
        const unsubscribe = firestore
            .collection(`mailboxes/${mailboxId}/messages`)
            .orderBy("date", "desc")
            .startAt(state.startAt || moment().add(1, "year").toDate())
            .limit(LIMIT)
            .onSnapshot(
                (querySnapshot) => {
                    if (querySnapshot.empty) {
                        return dispatch({
                            id: mailboxId,
                            type: UseMailboxMessagesActions.set,
                            payload: [],
                        });
                    }
                    return dispatch({
                        id: mailboxId,
                        type: UseMailboxMessagesActions.set,
                        payload: querySnapshot.docs.map((doc) =>
                            MailperCommonFirebase.documentSnapshotToMailboxMessage(doc)
                        ),
                    });
                },
                () => {
                    return dispatch({
                        id: mailboxId,
                        type: UseMailboxMessagesActions.set,
                        payload: [],
                    });
                }
            );
        return () => {
            unsubscribe();
        };
    }, [firestore, user, mailboxId, state.startAt]);

    const next = () =>
        dispatch({
            type: UseMailboxMessagesActions.next,
        });

    const prev = () =>
        dispatch({
            type: UseMailboxMessagesActions.prev,
        });

    return { ...state, prev, next } as IUseMailboxMessagesState;
};
