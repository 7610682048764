import React from "react";
import { useIntl } from "react-intl";
import { IonImg } from "@ionic/react";

interface IMailboxEmptyProps {
    email: string | undefined;
}

const MailboxEmpty: React.FC<IMailboxEmptyProps> = ({ email }) => {
    const { formatMessage: t } = useIntl();
    return (
        <div
            style={{
                top: "calc(50% - 98px)",
                width: "calc(100% - 20px)",
                textAlign: "center",
                position: "fixed"
            }}
        >
            <IonImg
                style={{
                    maxWidth: 192,
                    margin: "auto auto 32px auto"
                }}
                src={"assets/images/empty.svg"}
            />
            <p
                style={{
                    fontSize: 28,
                    margin: 0,
                    fontWeight: "bold"
                }}
            >
                {t({ id: "mailboxEmptyTitle" })}
            </p>
            <p
                style={{
                    fontSize: 16,
                    letterSpacing: 1,
                    color: "gray"
                }}
            >
                {t({ id: "mailboxEmptySubtitle" })} {email}
            </p>
        </div>
    );
};

export default MailboxEmpty;
