import { useReducer } from "react";
import { useFirebase } from "../providers/firebase-provider";
import { PurchaseTypes } from "@mail-per/common";

enum UsePurchaseCreateActions {
    reset,
    purchase,
    purchaseSucess,
    purchaseError,
}

interface IUsePurchaseCreateState {
    loading: boolean;
    success: string | null;
    error: string | null;
}

const initialState: IUsePurchaseCreateState = {
    loading: false,
    success: null,
    error: null,
};

const reducer = (state: any, action: any) => {
    switch (action.type) {
        case UsePurchaseCreateActions.reset:
            return initialState;
        case UsePurchaseCreateActions.purchase:
            return {
                ...state,
                loading: true,
            };
        case UsePurchaseCreateActions.purchaseSucess:
            return {
                ...state,
                loading: false,
                success: action.payload,
                error: null,
            };
        case UsePurchaseCreateActions.purchaseError:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        default:
            return state;
    }
};

export const usePurchaseCreate = () => {
    const { analytics, functions } = useFirebase();
    const [state, dispatch] = useReducer(reducer, initialState);

    const purchase = async (email: string, expiration: number, type: PurchaseTypes) => {
        // Analitycs
        analytics.logEvent("purchase_mailbox", { email, type });
        //
        dispatch({
            type: UsePurchaseCreateActions.purchase,
        });
        try {
            const createPurchase = functions.httpsCallable("createPurchase");
            const { data: purchase } = await createPurchase({
                email: email,
                expiration,
                type: type,
            });
            dispatch({
                type: UsePurchaseCreateActions.purchaseSucess,
                payload: purchase.id,
            });
            return purchase.id;
        } catch (e) {
            dispatch({
                type: UsePurchaseCreateActions.purchaseError,
                payload: `Error creating the mailbox ${email}`,
            });
        }
    };

    return {
        state: state as IUsePurchaseCreateState,
        purchase,
    };
};
