import React, { CSSProperties } from "react";
import moment from "moment";

interface TextDateProps {
    date: Date;
    style?: CSSProperties;
}

const TextDate: React.FC<TextDateProps> = ({ date, style = {} }) => {
    let formatedDate = moment(date).format("MMM D");
    if (moment().isSame(date, "day")) {
        formatedDate = moment(date).format("h:mm A");
    }
    if (!moment().isSame(date, "year")) {
        formatedDate = moment(date).format("MMM D, YYYY");
    }
    return (
        <div id="text-date" style={style}>
            {formatedDate}
        </div>
    );
};

export default TextDate;
