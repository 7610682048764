import React, { useMemo, useState } from "react";
import { IonButton, IonAvatar, IonImg, IonPopover, IonItem, IonList, IonText } from "@ionic/react";
import { useHistory } from "react-router";
import { useIntl } from "react-intl";
import { useFirebase } from "../providers/firebase-provider";
import { useAuthState } from "react-firebase-hooks/auth";
import SignInModal from "./sign-in-modal";

const UserMenuButton: React.FC = () => {
    const history = useHistory();
    const { auth } = useFirebase();
    const [user] = useAuthState(auth);
    const { formatMessage: t } = useIntl();
    const [showMenu, setShowMenu] = useState<Event | undefined>(undefined);
    const [showModal, setShowModal] = useState(false);
    const [changeAccount, setChangeAccount] = useState(false);

    const onSignIn = async () => {
        setShowMenu(undefined);
        setChangeAccount(false);
        setShowModal(true);
    };

    const onChangeAccount = async () => {
        setShowMenu(undefined);
        setChangeAccount(true);
        setShowModal(true);
    };

    const onSignOut = async () => {
        setShowMenu(undefined);
        await auth.signOut();
        // Reload screen
        // to clean leaked states
        window.location.reload();
    };

    const onExpired = () => {
        setShowMenu(undefined);
        history.push("/expired");
    };

    const { displayName, photoURL } = useMemo(() => {
        if (user && !user.isAnonymous) {
            // Find first provider with name and photo
            let provider = user.providerData.find(
                (providerData) => !!providerData?.displayName && !!providerData.photoURL
            );
            // Find first provider with name
            if (!provider) {
                provider = user.providerData.find((providerData) => !!providerData?.displayName);
            }
            // Find first provider with photo
            if (!provider) {
                provider = user.providerData.find((providerData) => !!providerData?.photoURL);
            }
            if (provider) {
                return {
                    displayName: provider.displayName,
                    photoURL: provider.photoURL,
                };
            }
        }
        return {
            displayName: null,
            photoURL: null,
        };
    }, [user]);

    return (
        <>
            {!user || user.isAnonymous ? (
                <IonButton onClick={onSignIn}>{t({ id: "signIn" })}</IonButton>
            ) : (
                <>
                    <IonButton onClick={(e) => setShowMenu(e.nativeEvent)}>
                        <IonText className="ion-hide-sm-down">{displayName}</IonText>
                        <IonAvatar style={{ height: 32, width: 32, marginLeft: 8 }}>
                            <IonImg
                                src={photoURL || "/assets/icons/icon-outer.png"}
                                alt={displayName || "User image"}
                            />
                        </IonAvatar>
                    </IonButton>
                    <IonPopover
                        isOpen={!!showMenu}
                        event={showMenu}
                        onDidDismiss={(e) => setShowMenu(undefined)}
                    >
                        <IonList lines="none" className="ion-padding-vertical">
                            <IonItem detail={false} button onClick={onChangeAccount}>
                                {t({ id: "changeAccount" })}
                            </IonItem>
                            <IonItem detail={false} button onClick={onExpired}>
                                {t({ id: "mailboxesExpired" })}
                            </IonItem>
                            <IonItem detail={false} button onClick={onSignOut}>
                                {t({ id: "signOut" })}
                            </IonItem>
                        </IonList>
                    </IonPopover>
                </>
            )}
            <SignInModal
                open={showModal}
                changeAccount={changeAccount}
                onClose={() => setShowModal(false)}
            />
        </>
    );
};

export default UserMenuButton;
