import React, { useState } from "react";
import { useIntl } from "react-intl";
import { IonButton, IonPopover, IonItem, IonList } from "@ionic/react";
import { useTranslations } from "../providers/translations-provider";

const UserLanguageSelector: React.FC = () => {
    const { language, languages, setLanguage } = useTranslations();
    const { formatMessage: t } = useIntl();
    const [showMenu, setShowMenu] = useState<Event | undefined>(undefined);

    return (
        <>
            <IonButton color="primary" onClick={e => setShowMenu(e.nativeEvent)}>
                {t({ id: `language${language.toUpperCase()}` })}
            </IonButton>
            <IonPopover
                isOpen={!!showMenu}
                event={showMenu}
                onDidDismiss={e => setShowMenu(undefined)}
            >
                <IonList lines="none" className="ion-padding-vertical">
                    {languages.map(item => (
                        <IonItem
                            detail={false}
                            button
                            key={item}
                            onClick={() => {
                                setShowMenu(undefined);
                                setLanguage(item);
                            }}
                        >
                            {t({ id: `language${item.toUpperCase()}` })}
                        </IonItem>
                    ))}
                </IonList>
            </IonPopover>
        </>
    );
};

export default UserLanguageSelector;
