export default {
  yes: "Yes",
  no: "No",
  hours: "hours",
  day: "day",
  days: "days",
  week: "week",
  month: "mes",
  year: "year",
  next: "Next",
  free: "Free",
  subtotal: "Subtotal",
  payment: "Payment",
  recover: "Recover",
  home: "Home",
  name: "Name",
  cancel: "Cancel",
  email: "Email",
  checkout: "Checkout",
  expire: "Expire",
  expiration: "Expiration",
  expired: "Expired",
  confirmation: "Confirmation",
  mailbox: "Mailbox",
  mailboxes: "Mailboxes",
  attachment: "Attachment",
  attachments: "Attachments",
  languageEN: "English",
  languageES: "Spanish",
  homeSlogan: "Disposable mailboxes",
  homeEmptyTitle: "Create your first mailbox",
  homeEmptySubtitle: "to start receiving emails",
  homeAddMailbox: "Add Mailbox",
  homeSlide1Section1:
    "You can create a mailbox in less than 30 seconds, doing just three steps:",
  homeSlide1Section2: "Press",
  homeSlide1Section3: "Insert the username for the email.",
  homeSlide1Section4: "Select the expiration time.",
  homeSlide2Section1:
    "We hope you enjoy Mailper; let us know if you have suggestions about the product.",
  homeSlide2Section2: "You can contact us using the email",
  signIn: "Sign In",
  signOut: "Logout",
  changeAccount: "Switch account",
  signInSubtitle:
    "Mailper is completely anonymous, but in case you want to use your disposable mailboxes in other devices you will need to login.",
  signInDisclaimer:
    "Your social account can be used to recover and access your mailboxes.",
  mailboxEmptyTitle: "This mailbox is empty",
  mailboxEmptySubtitle: "send some emails to ",
  mailboxExists: "The mailbox {email} is taken.",
  mailboxNew: "New Mailbox",
  mailboxInsertEmail: "email",
  mailboxSelectDomain: "Select domain",
  mailboxCreate: "Create mailbox",
  mailboxCreating: "Creating mailbox",
  mailboxRecovering: "Recovering mailbox",
  mailboxSignInRequired: "You need to Sign In before create a premium mailbox.",
  mailboxDisclaimer:
    "Although it is not required, we recommend you Sign In to access your mailboxes from other devices or retrieve them after they expire.",
  mailboxesExpired: "Expired mailboxes",
  purchaseReadyTitle: "Your mailbox is ready",
  purchaseReadySubtitle: "It's available in the home page",
  purchasePendingTitle: "Your mailbox is being created",
  purchasePendingSubtitle:
    "Wait a few seconds and check your mailbox in the home page",
  expiredEmptyTitle: "Woot woot",
  expiredEmptySubtitle: "You don't have expired mailboxes",
  recoverFor: "Recover - {price}",
  recoverForMessage:
    "Do you want to recover this mailbox for a full year for the price {price}?",
  recoverSubtitle: "You can recover your mailboxes for {price}",
  privacy: "Privacy",
  terms: "Terms",
  support: "Support",
  contactUsTitle: "Contact Us",
  contactUsSubtitle: "Let us know your suggestions or issues",
  theme: "Theme",
  forward: "Forward",
  forwardSubtitle: "Forward messages to your personal email address.",
  forwardInputPlaceholder: "Your personal email",
  forwardSkipTitle: "No Forwarding",
  forwardSkipSubTitle: "Block forwarding",
  forwardAddEmail: "New email",
  loading: "Loading",
  saving: "Saving",
  save: "Save",
  add: "Add",
  verify: "Verify",
  verified: "Verified",
  verification: "Verification",
  verificationSubtitle: "Check your personal email for the code.",
  code: "Code",
  addAddress: "Add Address",
  settings: "Settings",
  newVersion:
    "Heads up! A new version of Mailper will be released on the upcoming days, click here to check the preview.",
};
