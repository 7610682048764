import React from "react";
import emailAddresses from "email-addresses";

const colorPalette = [
    "#1abc9c",
    "#2ecc71",
    "#3498db",
    "#9b59b6",
    "#34495e",
    "#16a085",
    "#27ae60",
    "#2980b9",
    "#8e44ad",
    "#2c3e50",
    "#f1c40f",
    "#e67e22",
    "#e74c3c",
    "#95a5a6",
    "#f39c12",
    "#d35400",
    "#c0392b",
    "#bdc3c7",
    "#7f8c8d"
];

const generateColor = (initials: string) => {
    const index = initials.charCodeAt(0) - 65;
    return colorPalette[Math.abs(index) % colorPalette.length];
};

interface TextAvatarProps {
    text: string;
    isEmail?: boolean;
}

const TextAvatar: React.FC<TextAvatarProps> = ({ text, isEmail }) => {
    let initials = text ? text.substr(0, 1).toUpperCase() : "-";
    if (isEmail && text) {
        const emailParts = emailAddresses.parseOneAddress(text) as any;
        initials = (emailParts.name || emailParts.address || text)
            .substr(0, 1)
            .toUpperCase();
    }
    return (
        <div
            id="text-avatar"
            style={{
                backgroundColor: generateColor(initials),
                width: 32,
                height: 32,
                color: "white",
                fontSize: 18,
                borderRadius: "50%",
                textAlign: "center",
                paddingTop: 5,
                marginRight: 12
            }}
        >
            {initials}
        </div>
    );
};

export default TextAvatar;
