import React from "react";
import { useIntl } from "react-intl";
import { IonItem, IonLabel, IonList } from "@ionic/react";
import { IPurchase, PurchaseTypes } from "@mail-per/common";
import { usePackages } from "../hooks/use-packages";

interface MailboxCreatePaymentProps {
    purchase: IPurchase;
}

const MailboxPurchaseReceipt: React.FC<MailboxCreatePaymentProps> = ({ purchase }) => {
    const { formatMessage: t } = useIntl();
    const { create, recover } = usePackages();
    const packages = purchase.type === PurchaseTypes.create ? create : recover;
    return (
        <IonList lines="none">
            <IonItem className="ion-text-end">
                {t({ id: "mailbox" })}
                <IonLabel color="primary" className="ion-letter-spacing-1 ion-text-bold">
                    {purchase.email}
                </IonLabel>
            </IonItem>
            <IonItem className="ion-text-end">
                {t({ id: "expiration" })}
                <IonLabel color="primary">{packages[purchase.expiration].description}</IonLabel>
            </IonItem>
        </IonList>
    );
};

export default MailboxPurchaseReceipt;
