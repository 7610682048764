import { useAuthState } from "react-firebase-hooks/auth";
import { useFirebase } from "../providers/firebase-provider";
import { useMailboxes } from "./use-mailboxes";

export const useHasExpiredMailboxes = () => {
    const { auth } = useFirebase();
    const [user] = useAuthState(auth);
    const { mailboxes: expiredMailboxes } = useMailboxes({ expired: true, limit: 1 });
    return !!expiredMailboxes.length && !user?.isAnonymous;
};
