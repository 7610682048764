import React, { useEffect } from "react";
import {
    IonContent,
    IonHeader,
    IonPage,
    IonToolbar,
    IonButtons,
    IonBackButton,
    IonImg,
    IonProgressBar,
    IonButton,
} from "@ionic/react";
import { useIntl } from "react-intl";
import { RouteComponentProps } from "react-router";
import { usePurchase } from "../hooks/use-purchase";
import MailboxPurchaseReceipt from "../components/mailbox-purchase-receipt";

interface IConfirmationPageProps
    extends RouteComponentProps<{
        purchase: string;
    }> {}

const PurchasePage: React.FC<IConfirmationPageProps> = ({ match, history }) => {
    const { formatMessage: t } = useIntl();
    const { loading, purchase } = usePurchase(match.params.purchase);
    useEffect(() => {
        if (!loading && !purchase) {
            history.replace("/");
        }
    }, [history, loading, purchase]);
    return (
        <IonPage>
            <IonHeader className="no-borders">
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonBackButton defaultHref="/" />
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                {loading || !purchase ? (
                    <IonProgressBar type="indeterminate" />
                ) : (
                    <div
                        className="ion-text-center ion-padding"
                        style={{ maxWidth: 400, margin: "auto" }}
                    >
                        <IonImg
                            style={{
                                maxWidth: 256,
                                margin: "32px auto 32px auto",
                            }}
                            src={`assets/images/${
                                purchase.completed ? "inbox-cleanup.svg" : "loading.svg"
                            }`}
                        />
                        {purchase.completed && (
                            <>
                                <p
                                    style={{
                                        fontSize: 28,
                                        fontWeight: "bold",
                                    }}
                                >
                                    {t({ id: "purchaseReadyTitle" })}
                                </p>
                                <p
                                    className="ion-letter-spacing-1"
                                    style={{
                                        color: "grey",
                                    }}
                                >
                                    {t({ id: "purchaseReadySubtitle" })}
                                </p>
                                <MailboxPurchaseReceipt purchase={purchase} />
                            </>
                        )}
                        {!purchase.completed && (
                            <>
                                <p
                                    style={{
                                        fontSize: 28,
                                        fontWeight: "bold",
                                    }}
                                >
                                    {t({ id: "purchasePendingTitle" })}
                                </p>
                                <p
                                    className="ion-letter-spacing-1"
                                    style={{
                                        color: "grey",
                                    }}
                                >
                                    {t({ id: "purchasePendingSubtitle" })}
                                </p>
                            </>
                        )}
                        <IonButton shape="round" routerLink="/">
                            {t({ id: "home" })}
                        </IonButton>
                    </div>
                )}
            </IonContent>
        </IonPage>
    );
};

export default PurchasePage;
