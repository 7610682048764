import "moment/locale/es";
import React, { ReactNode, createContext, useContext, useReducer } from "react";
import { IntlProvider } from "react-intl";

import * as _en from "../translations/en";
import * as _es from "../translations/es";
import moment from "moment";

const STORAGE_PREFIX = "MAILPER::LANGUAGE";

const messages: any = {
    en: _en.default,
    es: _es.default
};

interface ITranslationsContext {
    language: string;
    languages: string[];
    messages: any;
    setLanguage: Function;
}

const TranslationsContext = createContext<ITranslationsContext>(
    {} as ITranslationsContext
);

export const useTranslations = () => useContext(TranslationsContext);

const TranslationIntlProvider = ({ children }: { children: ReactNode }) => {
    const { language, messages } = useTranslations();
    return (
        <IntlProvider locale={language} messages={messages}>
            {children}
        </IntlProvider>
    );
};

const initialLanguage = (rawLanguage: string) => {
    try {
        const language =
            localStorage.getItem(STORAGE_PREFIX) || rawLanguage.split("-")[0];
        if (messages[language]) {
            moment.locale(language);
            return language;
        } else {
            moment.locale("en");
            return "en";
        }
    } catch {
        moment.locale("en");
        return "en";
    }
};

const initialState = {
    language: initialLanguage(navigator.language),
    languages: Object.keys(messages),
    messages: messages[initialLanguage(navigator.language)]
};

const reducer = (state: any, action: any) => {
    switch (action.type) {
        case "setLanguage":
            return {
                ...state,
                language: action.payload,
                messages: messages[action.payload]
            };
        default:
            return state;
    }
};

export const TranslationsProvider = ({ children }: { children: ReactNode }) => {
    const [state, dispatch] = useReducer(reducer, initialState);

    const setLanguage = (language: string) => {
        localStorage.setItem(STORAGE_PREFIX, language);
        moment.locale(language);
        dispatch({ type: "setLanguage", payload: language });
    };

    return (
        <TranslationsContext.Provider value={{ ...state, setLanguage }}>
            <TranslationIntlProvider>{children}</TranslationIntlProvider>
        </TranslationsContext.Provider>
    );
};
