import { useEffect, useReducer } from "react";
import { IMailbox, MailperCommonFirebase } from "@mail-per/common";
import { useAuthState } from "react-firebase-hooks/auth";
import { useFirebase } from "../providers/firebase-provider";

const DEFAULT_LIMIT = 1000;

enum UseMailboxesActions {
    get,
    set,
}

interface IUseMailboxesState {
    loading: boolean;
    mailboxes: IMailbox[];
}

const initialState: IUseMailboxesState = {
    loading: true,
    mailboxes: [],
};

const reducer = (state: any, action: any) => {
    switch (action.type) {
        case UseMailboxesActions.get:
            return {
                ...state,
                loading: true,
            };
        case UseMailboxesActions.set:
            return {
                ...state,
                loading: false,
                mailboxes: action.payload,
            };
        default:
            return state;
    }
};

export const useMailboxes = (variables?: { root?: boolean; expired?: boolean; limit?: number }) => {
    const { auth, firestore } = useFirebase();
    const [user] = useAuthState(auth);
    const [state, dispatch] = useReducer(reducer, initialState);
    useEffect(() => {
        dispatch({ type: UseMailboxesActions.get });
        let query;
        if (!variables?.root) {
            query = firestore
                .collection("mailboxes")
                .where("disabled", "==", !!variables?.expired)
                .where("user", "==", user?.uid);
        } else {
            query = firestore.collection("mailboxes");
        }
        const unsubscribe = query
            .orderBy("createdAt", "desc")
            .limit(variables?.limit || DEFAULT_LIMIT)
            .onSnapshot(
                (querySnapshot) => {
                    if (querySnapshot.empty) {
                        return dispatch({
                            type: UseMailboxesActions.set,
                            payload: [],
                        });
                    }
                    return dispatch({
                        type: UseMailboxesActions.set,
                        payload: querySnapshot.docs.map((doc) =>
                            MailperCommonFirebase.documentSnapshotToMailbox(doc)
                        ),
                    });
                },
                () => {
                    return dispatch({
                        type: UseMailboxesActions.set,
                        payload: [],
                    });
                }
            );
        return () => {
            unsubscribe();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [firestore, user]);
    return state as IUseMailboxesState;
};
