import React from "react";
import moment from "moment";
import { IMailboxMessage } from "@mail-per/common";
import { IonItem, IonLabel, IonText } from "@ionic/react";
import TextAvatar from "./text-avatar";
import TextEmail from "./text-email";
import TextHtml from "./text-html";
import MessageAttachments from "./message-attachments";

interface MessageContentProps {
    message: IMailboxMessage;
}

const MessageContent: React.FC<MessageContentProps> = ({ message }) => {
    return (
        <>
            <IonItem lines="none" style={{ paddingTop: 16 }}>
                <TextAvatar text={message.from} isEmail={true} />
                <IonLabel>
                    <IonText>
                        <TextEmail email={message.from} />
                    </IonText>
                    <p>
                        <TextEmail onlyEmail={true} email={message.from} />
                    </p>
                </IonLabel>
                <div>
                    <IonLabel style={{ color: "gray" }} className="ion-text-capitalize">
                        {moment(message.date).format("MMM M, YYYY h:mm A")}
                    </IonLabel>
                </div>
            </IonItem>
            <IonItem>
                <IonText style={{ color: "gray" }}>{message.subject}</IonText>
            </IonItem>
            <TextHtml
                style={{
                    padding: 16,
                    overflow: "auto",
                }}
                html={message.html || message.text}
            />
            <MessageAttachments message={message} />
        </>
    );
};

export default MessageContent;
