import React from "react";
import {
    documentOutline,
    readerOutline,
    chatboxEllipsesOutline,
    moonOutline,
    sunnyOutline,
    alarmOutline,
    alertCircleOutline,
} from "ionicons/icons";
import { useIntl } from "react-intl";
import { useHistory } from "react-router";
import { IonIcon, IonLabel, IonRippleEffect } from "@ionic/react";
import { useTheme, IThemes } from "../providers/theme-provider";

interface HomeMenuVertButtonProps {
    text: string;
    icon: string;
    alert?: boolean;
    onClick: Function;
}

const HomeMenuVertButton: React.FC<HomeMenuVertButtonProps> = ({ text, icon, onClick, alert }) => {
    return (
        <div
            onClick={() => onClick()}
            style={{
                height: 100,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                color: "gray",
                cursor: "pointer",
                margin: 4,
                position: "relative",
            }}
            className="ion-activatable"
        >
            {alert && (
                <IonIcon
                    style={{
                        color: "white",
                        position: "absolute",
                        backgroundColor: "red",
                        borderRadius: "50%",
                        top: 20,
                        right: 16,
                    }}
                    icon={alertCircleOutline}
                />
            )}
            <IonIcon icon={icon} style={{ fontSize: 32, marginBottom: 8 }} />
            <IonLabel>{text}</IonLabel>
            <IonRippleEffect></IonRippleEffect>
        </div>
    );
};

interface HomeMenuVertProps {
    hasExpiredMailboxes: boolean;
}

const HomeMenuVert: React.FC<HomeMenuVertProps> = ({ hasExpiredMailboxes }) => {
    const history = useHistory();
    const { formatMessage: t } = useIntl();
    const { theme, toggleTheme } = useTheme();

    return (
        <div className="ion-hide-lg-down" style={{ width: 128 }}>
            <HomeMenuVertButton
                text={t({ id: "privacy" })}
                icon={documentOutline}
                onClick={() => history.push("/privacy")}
            />
            <HomeMenuVertButton
                text={t({ id: "terms" })}
                icon={readerOutline}
                onClick={() => history.push("/terms")}
            />
            <HomeMenuVertButton
                text={t({ id: "support" })}
                icon={chatboxEllipsesOutline}
                onClick={() => history.push("/contact")}
            />
            <HomeMenuVertButton
                text={t({ id: "theme" })}
                icon={theme === IThemes.dark ? sunnyOutline : moonOutline}
                onClick={() => toggleTheme()}
            />
            {hasExpiredMailboxes && (
                <HomeMenuVertButton
                    alert
                    text={t({ id: "expired" })}
                    icon={alarmOutline}
                    onClick={() => history.push("/expired")}
                />
            )}
        </div>
    );
};

export default HomeMenuVert;
