import React from "react";
import { useIntl } from "react-intl";
import { IonImg } from "@ionic/react";

const ExpiredEmpty: React.FC = () => {
    const { formatMessage: t } = useIntl();
    return (
        <div
            style={{
                top: "calc(50% - 100px)",
                left: "calc(50% - 140px)",
                textAlign: "center",
                position: "fixed"
            }}
        >
            <IonImg
                style={{
                    maxWidth: 192,
                    margin: "auto auto 32px auto"
                }}
                src={"assets/images/back-in-the-day.svg"}
            />
            <p
                style={{
                    fontSize: 28,
                    margin: 0,
                    fontWeight: "bold"
                }}
            >
                {t({ id: "expiredEmptyTitle" })}
            </p>
            <p
                style={{
                    fontSize: 16,
                    letterSpacing: 1,
                    color: "gray"
                }}
            >
                {t({ id: "expiredEmptySubtitle" })}
            </p>
        </div>
    );
};

export default ExpiredEmpty;
