import {
  IonContent,
  IonHeader,
  IonPage,
  IonToolbar,
  IonProgressBar,
  IonButtons,
  IonMenu,
  IonMenuButton,
} from "@ionic/react";
import { useIntl } from "react-intl";
import React, { useState } from "react";
import { RouteComponentProps } from "react-router";
import { useMailboxes } from "../hooks/use-mailboxes";
import FabTextButton from "../components/fab-text-button";
import MailboxCreateModal from "../components/mailbox-create-modal";
import UserMenuButton from "../components/user-menu-button";
import HomeEmpty from "../components/home-empty";
import UserLanguageSelector from "../components/user-language-selector";
import MailboxCard from "../components/mailbox-card";
import HomeMenuVert from "../components/home-menu-vert";
import HomeMenu from "../components/home-menu";
import { useHasExpiredMailboxes } from "../hooks/use-has-expired-mailboxes";

const HomePage: React.FC<RouteComponentProps> = ({ history }) => {
  const { loading, mailboxes } = useMailboxes();
  const hasExpiredMailboxes = useHasExpiredMailboxes();
  const { formatMessage: t } = useIntl();
  const [openCreateModal, setOpenCreateModal] = useState(false);
  return loading ? (
    <IonProgressBar type="indeterminate" />
  ) : (
    <>
      <IonMenu contentId="main-content" className="ion-hide-lg-up">
        <HomeMenu hasExpiredMailboxes={hasExpiredMailboxes} />
      </IonMenu>

      <IonPage id="main-content">
        <div
          onClick={() => {
            window.location.href = "https://dev.mailper.com";
          }}
          className="ion-padding ion-text-center"
          style={{
            cursor: "pointer",
            color: "white",
            backgroundColor: "var(--ion-color-primary)",
          }}
        >
          {t({ id: "newVersion" })}
        </div>
        <IonHeader className="no-borders">
          <IonToolbar>
            <IonButtons slot="start" className="ion-hide-lg-up">
              <IonMenuButton />
            </IonButtons>
            <IonButtons slot="primary">
              <UserLanguageSelector />
              <UserMenuButton />
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <div style={{ display: "flex", height: "100%" }}>
            <HomeMenuVert hasExpiredMailboxes={hasExpiredMailboxes} />
            <div style={{ width: "100%" }}>
              <div style={{ maxWidth: 600, margin: "auto", paddingBottom: 92 }}>
                {mailboxes.length ? (
                  <>
                    <h1
                      className="ion-hide-lg-down ion-text-center"
                      style={{ fontSize: 56, marginBottom: 32 }}
                    >
                      {t({ id: "mailboxes" })}
                    </h1>
                    <div>
                      {mailboxes.map((mailbox) => (
                        <MailboxCard
                          key={mailbox.id}
                          mailbox={mailbox}
                          onClick={() => history.push(`/mailbox/${mailbox.id}`)}
                        />
                      ))}
                    </div>
                  </>
                ) : (
                  <HomeEmpty onClick={() => setOpenCreateModal(true)} />
                )}
              </div>
            </div>
            <div className="ion-hide-lg-down" style={{ width: 128 }}></div>
          </div>
          <FabTextButton
            style={{
              textTransform: "none",
              position: "fixed",
              bottom: 16,
              right: 16,
            }}
            text={t({ id: "homeAddMailbox" })}
            onClick={() => setOpenCreateModal(true)}
          />
          <MailboxCreateModal
            open={openCreateModal}
            onClose={() => setOpenCreateModal(false)}
          />
        </IonContent>
      </IonPage>
    </>
  );
};

export default HomePage;
