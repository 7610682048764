import React, { useState } from "react";
import { useIntl } from "react-intl";
import {
    IonContent,
    IonHeader,
    IonPage,
    IonToolbar,
    IonButtons,
    IonBackButton,
    IonTitle,
    IonProgressBar,
    IonLoading,
    IonAlert,
    IonButton,
    IonIcon,
} from "@ionic/react";
import { IMailbox, PurchaseTypes } from "@mail-per/common";
import { loadStripe } from "@stripe/stripe-js";
import { useFirebase } from "../providers/firebase-provider";
import { usePackages } from "../hooks/use-packages";
import { useMailboxes } from "../hooks/use-mailboxes";
import { usePurchaseCreate } from "../hooks/use-purchase-create";
import ExpiredEmpty from "../components/expired-empty";
import MailboxCard from "../components/mailbox-card";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY as string);

const ExpiredPage: React.FC = () => {
    const { auth } = useFirebase();
    const { formatMessage: t, locale } = useIntl();
    const { loading, mailboxes } = useMailboxes({ expired: true });
    const { recover: packages } = usePackages();

    const [recoverConfirm, setRecoverConfirm] = useState<IMailbox | undefined>();

    const {
        purchase,
        state: { loading: purchaseLoading, error: purchaseError },
    } = usePurchaseCreate();

    const onRecover = async (mailbox: IMailbox) => {
        const expiration = "8760";
        const stripe = await stripePromise;
        const purchaseId = await purchase(mailbox.email, Number(expiration), PurchaseTypes.recover);
        if (stripe && purchaseId) {
            stripe.redirectToCheckout({
                items: [{ sku: packages[expiration].sku, quantity: 1 }],
                customerEmail: auth.currentUser?.email as string,
                clientReferenceId: purchaseId,
                successUrl: `${process.env.REACT_APP_URL}/purchase/${purchaseId}`,
                cancelUrl: `${process.env.REACT_APP_URL}`,
                locale: locale as any,
            });
        }
    };

    return (
        <IonPage>
            <IonHeader className="no-borders">
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonBackButton defaultHref="/" />
                    </IonButtons>
                    <IonTitle className="ion-hide-lg-up">{t({ id: "mailboxesExpired" })}</IonTitle>
                    <IonButtons slot="end">
                        <IonButton routerLink="/stats">
                            <IonIcon />
                        </IonButton>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                {loading ? (
                    <IonProgressBar type="indeterminate" />
                ) : (
                    <div style={{ maxWidth: 600, margin: "auto" }}>
                        {mailboxes.length ? (
                            <>
                                <h1
                                    className="ion-text-center ion-hide-lg-down"
                                    style={{ fontSize: 38, marginBottom: 32 }}
                                >
                                    {t({ id: "mailboxesExpired" })}
                                </h1>
                                <p
                                    className="ion-text-center ion-padding-vertical"
                                    style={{ color: "gray" }}
                                >
                                    {t({ id: "recoverSubtitle" }, { price: "$9.99" })}
                                </p>
                                <div>
                                    {mailboxes.map((mailbox) => (
                                        <MailboxCard
                                            key={mailbox.id}
                                            mailbox={mailbox}
                                            expired={true}
                                            onClick={() => setRecoverConfirm(mailbox)}
                                        />
                                    ))}
                                </div>
                            </>
                        ) : (
                            <ExpiredEmpty />
                        )}
                    </div>
                )}
                <IonLoading message={t({ id: "mailboxRecovering" })} isOpen={purchaseLoading} />
                <IonAlert
                    isOpen={!!purchaseError}
                    header={"Error"}
                    message={purchaseError!}
                    buttons={["OK"]}
                />
                <IonAlert
                    isOpen={!!recoverConfirm}
                    onDidDismiss={() => setRecoverConfirm(undefined)}
                    header={t({ id: "confirmation" })}
                    message={t({ id: "recoverForMessage" }, { price: "$9.99" })}
                    buttons={[
                        {
                            text: t({ id: "no" }),
                            role: "cancel",
                            cssClass: "secondary",
                        },
                        {
                            text: t({ id: "yes" }),
                            handler: () => onRecover(recoverConfirm as IMailbox),
                        },
                    ]}
                />
            </IonContent>
        </IonPage>
    );
};

export default ExpiredPage;
