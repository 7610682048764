import { useEffect, useReducer } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { MailperCommonFirebase, IPurchase } from "@mail-per/common";
import { useFirebase } from "../providers/firebase-provider";

enum UsePurchaseActions {
    get,
    set
}

interface IUsePurchaseState {
    loading: boolean;
    purchase: IPurchase | null;
}

const initialState: IUsePurchaseState = {
    loading: true,
    purchase: null
};

const reducer = (state: any, action: any) => {
    switch (action.type) {
        case UsePurchaseActions.get:
            return {
                ...state,
                loading: true
            };
        case UsePurchaseActions.set:
            return {
                ...state,
                loading: false,
                purchase: action.payload
            };
        default:
            return state;
    }
};

export const usePurchase = (purchaseId: string) => {
    const { auth, firestore } = useFirebase();
    const [user] = useAuthState(auth);
    const [state, dispatch] = useReducer(reducer, initialState);
    useEffect(() => {
        dispatch({ type: UsePurchaseActions.get });
        const unsubscribe = firestore
            .collection(`purchases`)
            .doc(purchaseId)
            .onSnapshot(
                docSnapshot => {
                    if (!docSnapshot.exists) {
                        return dispatch({
                            type: UsePurchaseActions.set,
                            payload: null
                        });
                    }
                    return dispatch({
                        type: UsePurchaseActions.set,
                        payload: MailperCommonFirebase.documentSnapshotToPurchase(docSnapshot)
                    });
                },
                () => {
                    return dispatch({
                        type: UsePurchaseActions.set,
                        payload: null
                    });
                }
            );
        return () => {
            unsubscribe();
        };
    }, [firestore, user, purchaseId]);
    return state as IUsePurchaseState;
};
