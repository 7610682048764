import {
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    IonButtons,
    IonBackButton,
    IonList,
    IonProgressBar,
    IonGrid,
    IonCol,
    IonRow,
    IonButton,
    IonItem,
    IonIcon,
} from "@ionic/react";
import { arrowBack, arrowForward } from "ionicons/icons";
import React, { useEffect, useState } from "react";
import { RouteComponentProps } from "react-router";
import { useMailboxMessages } from "../hooks/use-mailbox-messages";
import { useMailbox } from "../hooks/use-mailbox";
import MessageItem from "../components/message-item";
import { IMailboxMessage } from "@mail-per/common";
import MessageContent from "../components/message-content";
import MailboxEmpty from "../components/mailbox-empty";
import { useMarkAsRead } from "../hooks/use-mark-as-read";
import MailboxRedirectModal from "../components/mailbox-redirect-modal";

interface IMailboxPageProps
    extends RouteComponentProps<{
        mailbox: string;
    }> {}

const MailboxPage: React.FC<IMailboxPageProps> = ({ match, history }) => {
    const [selected, setSelected] = useState<IMailboxMessage | null>(null);
    const { loading: loadingMailbox, mailbox } = useMailbox(match.params.mailbox);
    const { loading, messages, hasPrev, prev, hasNext, next } = useMailboxMessages(
        match.params.mailbox
    );
    const markAsRead = useMarkAsRead();

    useEffect(() => {
        if (!loadingMailbox && !mailbox) {
            history.replace("/");
        }
    }, [history, loadingMailbox, mailbox]);

    const onSelectMessage = (message: IMailboxMessage) => {
        // Mark message as read
        if (mailbox && !message.viewedAt) {
            markAsRead(mailbox.id, message.id);
        }
        // Select message
        setSelected(message);
        if (window.innerWidth <= 992) {
            history.push(`/mailbox/${mailbox?.id}/message/${message.id}`);
        }
    };

    return (
        <IonPage>
            <IonHeader className="no-borders">
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonBackButton defaultHref="/" />
                    </IonButtons>
                    <IonTitle color="primary">{mailbox?.email} </IonTitle>
                    <IonButtons slot="end">
                        <MailboxRedirectModal mailbox={mailbox} />
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                {loading ? (
                    <IonProgressBar type="indeterminate" />
                ) : (
                    <IonGrid className="scrollable">
                        <IonRow className="scrollable">
                            <IonCol
                                className="scrollable"
                                sizeXs="12"
                                sizeSm="12"
                                sizeMd="12"
                                sizeLg="4"
                                sizeXl="4"
                            >
                                {messages.length ? (
                                    <>
                                        <IonList>
                                            {messages.map((message) => (
                                                <MessageItem
                                                    key={message.id}
                                                    selected={selected?.id === message.id}
                                                    message={message}
                                                    onClick={() => onSelectMessage(message)}
                                                />
                                            ))}
                                            <IonItem lines="none">
                                                {hasPrev && (
                                                    <IonButton
                                                        slot="start"
                                                        fill="clear"
                                                        onClick={() => prev()}
                                                    >
                                                        <IonIcon
                                                            slot="icon-only"
                                                            icon={arrowBack}
                                                        />
                                                    </IonButton>
                                                )}
                                                {hasNext && (
                                                    <IonButton
                                                        slot="end"
                                                        fill="clear"
                                                        onClick={() => next()}
                                                    >
                                                        <IonIcon
                                                            slot="icon-only"
                                                            icon={arrowForward}
                                                        />
                                                    </IonButton>
                                                )}
                                            </IonItem>
                                        </IonList>
                                    </>
                                ) : (
                                    <MailboxEmpty email={mailbox?.email} />
                                )}
                            </IonCol>
                            <IonCol size="8" className="scrollable ion-hide-lg-down">
                                {selected && <MessageContent message={selected} />}
                            </IonCol>
                        </IonRow>
                    </IonGrid>
                )}
            </IonContent>
        </IonPage>
    );
};

export default MailboxPage;
