import React from "react";
import { IMailboxMessage } from "@mail-per/common";
import { IonItem, IonLabel, IonText, IonIcon } from "@ionic/react";
import { attach } from "ionicons/icons";
import TextAvatar from "./text-avatar";
import TextEmail from "./text-email";
import TextDate from "./text-date";

interface MessageItemProps {
    selected?: boolean;
    message: IMailboxMessage;
    onClick: Function;
}

const MessageItem: React.FC<MessageItemProps> = ({ selected, message, onClick }) => {
    const isRead = !!message.viewedAt;
    return (
        <IonItem
            key={message.id}
            onClick={() => onClick()}
            button={true}
            className={selected ? "selected" : ""}
        >
            <TextAvatar text={message.from} isEmail={true} />
            <IonLabel>
                <IonText>
                    <TextEmail
                        style={{ fontWeight: isRead ? "normal" : "bold" }}
                        email={message.from}
                    />
                </IonText>
                <p
                    style={{
                        fontWeight: isRead ? "normal" : "bold",
                        color: isRead ? "gray" : "black",
                    }}
                >
                    {message.subject}
                </p>
                <p>{message.text}</p>
            </IonLabel>
            <div className="ion-text-center">
                <IonLabel>
                    <TextDate
                        style={{
                            color: isRead ? "gray" : "black",
                            fontSize: 12,
                            fontWeight: isRead ? "normal" : "bold",
                        }}
                        date={message.date}
                    />
                </IonLabel>
                {message.attachments ? (
                    <IonIcon
                        icon={attach}
                        style={{
                            color: "gray",
                            margin: "8px auto",
                            fontSize: 24,
                        }}
                    />
                ) : null}
            </div>
        </IonItem>
    );
};

export default MessageItem;
