import moment from "moment";
import React from "react";
import { useIntl } from "react-intl";
import { IMailbox } from "@mail-per/common";
import { IonLabel, IonRippleEffect } from "@ionic/react";

interface MailboxCardProps {
    expired?: boolean;
    mailbox: IMailbox;
    onClick: Function;
}

const MailboxCard: React.FC<MailboxCardProps> = ({ mailbox, onClick, expired }) => {
    const { formatMessage: t } = useIntl();
    return (
        <div
            onClick={() => onClick()}
            style={{
                border: "1px solid #e0dede",
                borderRadius: 8,
                margin: 8,
                padding: 16,
                position: "relative",
                cursor: "pointer",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
            }}
            className="ion-activatable"
        >
            <IonLabel color="primary">{mailbox.email}</IonLabel>
            <p style={{ fontSize: 12, color: "gray" }}>
                {t({ id: expired ? "expired" : "expire" }) + " "}
                {moment(mailbox.expireAt).fromNow()}
            </p>
            <IonRippleEffect></IonRippleEffect>
        </div>
    );
};

export default MailboxCard;
