import { useEffect, useReducer } from "react";
import { MailperCommonFirebase, IMailbox } from "@mail-per/common";
import { useFirebase } from "../providers/firebase-provider";

enum UseMailboxActions {
    get,
    set,
}

interface IUseMailboxState {
    loading: boolean;
    mailbox: IMailbox | null;
}

const initialState: IUseMailboxState = {
    loading: true,
    mailbox: null,
};

const reducer = (state: any, action: any) => {
    switch (action.type) {
        case UseMailboxActions.get:
            return {
                ...state,
                loading: true,
            };
        case UseMailboxActions.set:
            return {
                ...state,
                loading: false,
                mailbox: action.payload,
            };
        default:
            return state;
    }
};

export const useMailbox = (mailboxId: string) => {
    const { firestore } = useFirebase();
    const [state, dispatch] = useReducer(reducer, initialState);
    useEffect(() => {
        if (!mailboxId) {
            return;
        }
        dispatch({ type: UseMailboxActions.get });
        const unsubscribe = firestore
            .collection(`mailboxes`)
            .doc(mailboxId)
            .onSnapshot(
                (docSnapshot) => {
                    if (!docSnapshot.exists) {
                        return dispatch({
                            type: UseMailboxActions.set,
                            payload: null,
                        });
                    }
                    return dispatch({
                        type: UseMailboxActions.set,
                        payload: MailperCommonFirebase.documentSnapshotToMailbox(docSnapshot),
                    });
                },
                () => {
                    return dispatch({
                        type: UseMailboxActions.set,
                        payload: null,
                    });
                }
            );
        return () => {
            unsubscribe();
        };
    }, [firestore, mailboxId]);
    return state as IUseMailboxState;
};
