import { useFirebase } from "../providers/firebase-provider";

export const useMarkAsRead = () => {
    const { functions } = useFirebase();

    const markAsRead = async (mailbox: string, message: string) => {
        try {
            const call = functions.httpsCallable("markAsRead");
            const { data: purchase } = await call({
                mailbox,
                message,
            });
            return purchase.id;
        } catch {}
    };

    return markAsRead;
};
