import React, { useEffect } from "react";
import { Redirect, Route } from "react-router-dom";
import { IonRouterOutlet, IonProgressBar } from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import { useAuthState } from "react-firebase-hooks/auth";
import { useFirebase } from "./providers/firebase-provider";
import HomePage from "./pages/home-page";
import TermsPage from "./pages/terms-page";
import PrivacyPage from "./pages/privacy-page";
import MailboxPage from "./pages/mailbox-page";
import MessagePage from "./pages/message-page";
import PurchasePage from "./pages/purchase-page";
import ExpiredPage from "./pages/expired-page";
import StatsPage from "./pages/stats-page";
import ContactPage from "./pages/contact-page";

export const Routes: React.FC = () => {
    const { auth, analytics } = useFirebase();
    const [user, loading] = useAuthState(auth);

    useEffect(() => {
        if (!user && !loading) {
            auth.signInAnonymously();
        }
        if (user && !loading) {
            analytics.setUserId(user.uid);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user, loading]);

    return loading || !user ? (
        <IonProgressBar type="indeterminate" />
    ) : (
        <IonReactRouter>
            <IonRouterOutlet>
                <Route path="/mailbox/:mailbox/message/:message" component={MessagePage} />
                <Route path="/mailbox/:mailbox" component={MailboxPage} />
                <Route path="/purchase/:purchase" component={PurchasePage} />
                <Route path="/expired" component={ExpiredPage} />
                <Route path="/contact" component={ContactPage} />
                <Route path="/terms" component={TermsPage} />
                <Route path="/privacy" component={PrivacyPage} />
                <Route path="/stats" component={StatsPage} />
                <Route path="/home" component={HomePage} />
                <Route render={() => <Redirect to="/home" />} />
            </IonRouterOutlet>
        </IonReactRouter>
    );
};
