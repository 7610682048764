import React from "react";
import {
    IonRow,
    IonCol,
    IonProgressBar,
    IonList,
    IonItem,
    IonLabel,
    IonButton,
    IonModal,
    IonIcon,
    IonItemDivider,
} from "@ionic/react";
import { arrowBack, arrowForward } from "ionicons/icons";
import { close, personOutline, serverOutline } from "ionicons/icons";
import MessageItem from "../components/message-item";
import { useMailboxMessages } from "../hooks/use-mailbox-messages";
import { useHistory } from "react-router";
import { useMailbox } from "../hooks/use-mailbox";

interface IStatsMailboxModal {
    mailboxId: string | undefined;
    setMailboxId: (mailboxId: string | undefined) => void;
    setUserId: (userId: string | undefined) => void;
}

const StatsMailboxModal: React.FC<IStatsMailboxModal> = ({
    mailboxId,
    setMailboxId,
    setUserId,
}) => {
    const history = useHistory();
    const { loading: loadingMailbox, mailbox } = useMailbox(mailboxId || "");
    const { loading: loadingMessages, messages, hasPrev, prev, hasNext, next } = useMailboxMessages(
        mailboxId || ""
    );
    return (
        <IonModal isOpen={!!mailboxId} backdropDismiss={false} cssClass="ion-modal-scrollable">
            <div>
                <IonRow className="ion-align-items-center ion-padding-start">
                    <IonCol>{mailbox?.email}</IonCol>
                    <IonCol className="ion-text-end">
                        <IonButton fill="clear" onClick={() => setMailboxId(undefined)}>
                            <IonIcon slot="icon-only" icon={close} />
                        </IonButton>
                    </IonCol>
                </IonRow>
                {loadingMailbox || loadingMessages ? (
                    <IonProgressBar type="indeterminate"></IonProgressBar>
                ) : (
                    <IonList>
                        <IonItem>
                            <IonIcon icon={serverOutline} slot="start" />
                            <IonLabel>{mailbox?.id}</IonLabel>
                        </IonItem>
                        <IonItem
                            button
                            onClick={() => {
                                setMailboxId(undefined);
                                setUserId(mailbox?.user);
                            }}
                        >
                            <IonIcon icon={personOutline} slot="start" />
                            <IonLabel>{mailbox?.user}</IonLabel>
                        </IonItem>
                        <IonItemDivider>
                            <IonLabel>Messages</IonLabel>
                        </IonItemDivider>
                        {messages.map((message) => (
                            <MessageItem
                                key={message.id}
                                message={message}
                                onClick={() => {
                                    setMailboxId(undefined);
                                    history.push(`/mailbox/${mailbox?.id}/message/${message.id}`);
                                }}
                            />
                        ))}
                        <IonItem lines="none">
                            {hasPrev && (
                                <IonButton slot="start" fill="clear" onClick={() => prev()}>
                                    <IonIcon slot="icon-only" icon={arrowBack} />
                                </IonButton>
                            )}
                            {hasNext && (
                                <IonButton slot="end" fill="clear" onClick={() => next()}>
                                    <IonIcon slot="icon-only" icon={arrowForward} />
                                </IonButton>
                            )}
                        </IonItem>
                    </IonList>
                )}
            </div>
        </IonModal>
    );
};

export default StatsMailboxModal;
