import React, { useState } from "react";
import {
    IonContent,
    IonHeader,
    IonPage,
    IonToolbar,
    IonButtons,
    IonBackButton,
    IonTitle,
    IonGrid,
    IonRow,
    IonCol,
    IonProgressBar,
    IonList,
    IonItem,
    IonLabel,
    IonText,
    IonButton,
    IonIcon,
} from "@ionic/react";
import { personOutline, serverOutline } from "ionicons/icons";
import * as Moment from "moment";
import { extendMoment } from "moment-range";
import { Bar } from "react-chartjs-2";
import { RouteComponentProps } from "react-router";
import { UseStats } from "../hooks/use-stats";
import { useMailboxes } from "../hooks/use-mailboxes";
import StatsMailboxModal from "../components/stats-mailbox-modal";
import StatsFindMailboxAlert from "../components/stats-find-mailbox-alert";
import StatsFindUserAlert from "../components/stats-find-user-alert";
import StatsUserModal from "../components/stats-user-modal";

const moment = extendMoment(Moment);

const StatsPage: React.FC<RouteComponentProps> = ({ history }) => {
    const { loading, stats } = UseStats();

    const [mailboxId, setMailboxId] = useState<string>();
    const [findingMailbox, setFindingMailbox] = useState(false);

    const [userId, setUserId] = useState<string>();
    const [findingUser, setFindingUser] = useState(false);

    const { mailboxes } = useMailboxes({ root: true, limit: 20 });

    const timeline = [] as any;
    const range = moment.range(moment(new Date()).subtract(6, "days"), moment().add(1, "days"));
    for (let day of range.by("day")) {
        timeline.push({
            date: moment(day).format("YYYY-MM-DD"),
            value: 0,
        });
    }

    const backgroundColor = "#eb445a";
    const datasets = (model: string) => {
        return {
            datasets: [
                {
                    backgroundColor,
                    data:
                        stats &&
                        stats[model] &&
                        timeline.map((item: any) => ({
                            x: item.date,
                            y: stats[model][item.date] || 0,
                        })),
                },
            ],
        };
    };

    const users_timeline = datasets("users_timeline");
    const mailboxes_timeline = datasets("mailboxes_timeline");
    const messages_timeline = datasets("messages_timeline");
    const purchases_timeline = datasets("purchases_timeline");

    const options = {
        maintainAspectRatio: false,
        legend: {
            display: false,
        },
        scales: {
            xAxes: [
                {
                    type: "time",
                    time: {
                        unit: "day",
                    },
                },
            ],
        },
    };

    const chartStyles = { maxWidth: 700, height: 320, margin: "auto" };

    return (
        <IonPage>
            <IonHeader className="no-borders">
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonBackButton defaultHref="/" />
                    </IonButtons>
                    <IonTitle>Stats</IonTitle>
                    <IonButtons slot="end">
                        <IonButton fill="clear" onClick={() => setFindingMailbox(true)}>
                            <IonIcon slot="icon-only" icon={serverOutline} />
                        </IonButton>
                        <IonButton fill="clear" onClick={() => setFindingUser(true)}>
                            <IonIcon slot="icon-only" icon={personOutline} />
                        </IonButton>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                {loading || !stats ? (
                    <IonProgressBar type="indeterminate" />
                ) : (
                    <IonGrid className="ion-letter-spacing-1 ion-padding-top">
                        <IonRow className="ion-text-center">
                            <IonCol sizeXs="12" sizeMd="6" style={{ marginBottom: 48 }}>
                                <p style={{ color: "gray" }}>Users</p>
                                <div style={{ fontSize: 96 }}>{stats.users_count}</div>
                                <div style={chartStyles}>
                                    <Bar data={users_timeline} options={options} />
                                </div>
                            </IonCol>
                            <IonCol sizeXs="12" sizeMd="6" style={{ marginBottom: 48 }}>
                                <p style={{ color: "gray" }}>Mailboxes</p>
                                <div style={{ fontSize: 96 }}>{stats.mailboxes_count}</div>
                                <div style={chartStyles}>
                                    <Bar data={mailboxes_timeline} options={options} />
                                </div>
                            </IonCol>
                            <IonCol sizeXs="12" sizeMd="6" style={{ marginBottom: 48 }}>
                                <p style={{ color: "gray" }}>Messages</p>
                                <div style={{ fontSize: 96 }}>{stats.messages_count}</div>
                                <div style={chartStyles}>
                                    <Bar data={messages_timeline} options={options} />
                                </div>
                            </IonCol>
                            <IonCol sizeXs="12" sizeMd="6" style={{ marginBottom: 48 }}>
                                <p style={{ color: "gray" }}>Purchases</p>
                                <div style={{ fontSize: 96 }}>{stats.purchases_count}</div>
                                <div style={chartStyles}>
                                    <Bar data={purchases_timeline} options={options} />
                                </div>
                            </IonCol>
                            <IonCol sizeXs="12" sizeMd="6" style={{ marginBottom: 48 }}>
                                <p style={{ color: "gray" }}>Last Mailboxes</p>
                                <IonList style={{ maxWidth: 700, margin: "auto" }}>
                                    {mailboxes.map((mailbox) => (
                                        <IonItem key={mailbox.id}>
                                            <IonLabel>
                                                <IonText>
                                                    <h3>{mailbox.email}</h3>
                                                </IonText>
                                                <p style={{ fontSize: 12 }}>
                                                    {moment(mailbox.createdAt).format("lll")}
                                                </p>
                                                <IonText style={{ fontSize: 12 }} color="primary">
                                                    {mailbox.user.substr(-8)}
                                                </IonText>
                                            </IonLabel>
                                            <IonButton
                                                onClick={() => setMailboxId(mailbox.id)}
                                                fill="outline"
                                                slot="end"
                                            >
                                                View
                                            </IonButton>
                                        </IonItem>
                                    ))}
                                </IonList>
                            </IonCol>
                        </IonRow>
                    </IonGrid>
                )}
                <StatsMailboxModal
                    mailboxId={mailboxId}
                    setMailboxId={setMailboxId}
                    setUserId={setUserId}
                />
                <StatsFindMailboxAlert
                    isOpen={findingMailbox}
                    onDismiss={() => setFindingMailbox(false)}
                    setMailboxId={setMailboxId}
                />
                <StatsUserModal userId={userId} setUserId={setUserId} setMailboxId={setMailboxId} />
                <StatsFindUserAlert
                    isOpen={findingUser}
                    onDismiss={() => setFindingUser(false)}
                    setUserId={setUserId}
                />
            </IonContent>
        </IonPage>
    );
};

export default StatsPage;
