import { useIntl } from "react-intl";

export const usePackages = () => {
    const { formatMessage: t } = useIntl();

    const create = {
        "6": {
            description: "6 " + t({ id: "hours" }),
            price: null,
            sku: null
        },
        "24": {
            description: "1 " + t({ id: "day" }),
            price: null,
            sku: null
        },
        "168": {
            description: "1 " + t({ id: "week" }),
            price: 3.99,
            sku:
                process.env.NODE_ENV === "development" ? "sku_H1ZMEpzHDsNy9l" : "sku_H1gGdW0CA37VLY"
        },
        "8760": {
            description: "1 " + t({ id: "year" }),
            price: 9.99,
            sku:
                process.env.NODE_ENV === "development" ? "sku_H1ZaI7BMLxz98a" : "sku_H1gGcvu1JttBVq"
        }
    };

    const recover = {
        "8760": {
            description: "1 " + t({ id: "year" }),
            price: 9.99,
            sku:
                process.env.NODE_ENV === "development" ? "sku_H1vUuc8bf16Zaq" : "sku_H1yRRGrF4ncKfc"
        }
    };

    return {
        create,
        recover
    } as any;
};
