import React, { CSSProperties, useState, useEffect } from "react";
import { useIntl } from "react-intl";
import {
    IonButton,
    IonIcon,
    IonModal,
    IonRow,
    IonCol,
    IonItem,
    IonLabel,
    IonInput,
    IonList,
    IonAlert,
    IonLoading,
} from "@ionic/react";
import {
    close,
    addOutline,
    arrowRedoOutline,
    closeCircleOutline,
    // chevronDown,
    // chevronUp,
} from "ionicons/icons";
import { IMailbox } from "@mail-per/common";
import { useFirebase } from "../providers/firebase-provider";

interface MailboxRedirectModalProps {
    mailbox: IMailbox | null;
    style?: CSSProperties;
}

const MailboxRedirectModal: React.FC<MailboxRedirectModalProps> = ({ mailbox }) => {
    const { analytics, functions } = useFirebase();
    const { formatMessage: t, locale } = useIntl();

    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);

    const [openBlacklist, setOpenBlacklist] = useState(false);
    const [openCode, setOpenCode] = useState(false);
    const [openAdvanced, setOpenAdvanced] = useState(false);

    const [email, setEmail] = useState<string>("");
    const [blacklist, setBlacklist] = useState<string[]>([]);
    const [verified, setVerified] = useState<boolean>(false);

    const onWillPresent = () => {
        setEmail(mailbox?.forwardConfig?.email || "");
        setBlacklist(mailbox?.forwardConfig?.blacklist || []);
        setVerified(mailbox?.forwardConfig?.email ? true : false);
        setOpenAdvanced(false);
    };

    const onSave = async () => {
        // Analitycs
        analytics.logEvent("forward_mailbox", { email });
        //
        setLoading(true);
        const forwardMailbox = functions.httpsCallable("forwardMailbox");
        try {
            await forwardMailbox({
                mailbox: mailbox?.id,
                email,
                blacklist,
            });
            setLoading(false);
            setOpen(false);
        } catch {
            setLoading(false);
        }
    };

    const onStartVerification = async () => {
        setLoading(true);
        const verifyEmail = functions.httpsCallable("verifyEmail");
        try {
            const result = await verifyEmail({ email, locale });
            if (result.data && result.data.verified) {
                setVerified(true);
            } else {
                setOpenCode(true);
            }
            setLoading(false);
        } catch {
            setLoading(false);
        }
    };

    const onCompleteVerification = async (code: string) => {
        setLoading(true);
        const verifyEmail = functions.httpsCallable("verifyEmail");
        try {
            const result = await verifyEmail({ email, code });
            if (result.data) {
                setVerified(true);
            }
            setLoading(false);
        } catch {
            setLoading(false);
        }
    };

    useEffect(() => {
        setVerified(email ? email === mailbox?.forwardConfig?.email : false);
    }, [email, mailbox]);

    return (
        <>
            <div className="ion-hide-lg-up">
                <IonButton fill="clear" onClick={() => setOpen(true)}>
                    <IonIcon slot="icon-only" icon={arrowRedoOutline} />
                </IonButton>
            </div>
            <div className="ion-hide-lg-down">
                <IonButton fill="clear" onClick={() => setOpen(true)}>
                    {t({ id: "forward" })}
                    <IonIcon icon={arrowRedoOutline} slot="end" />
                </IonButton>
            </div>
            <IonModal
                isOpen={open}
                onWillPresent={onWillPresent}
                backdropDismiss={false}
                cssClass="ion-modal-scrollable"
            >
                <IonRow className="ion-align-items-center ion-padding-start">
                    <IonCol>
                        <h5>{t({ id: "forward" })}</h5>
                    </IonCol>
                    <IonCol className="ion-text-end">
                        <IonButton fill="clear" onClick={() => setOpen(false)}>
                            <IonIcon slot="icon-only" icon={close} />
                        </IonButton>
                    </IonCol>
                </IonRow>
                <div className="ion-padding">
                    <p
                        style={{
                            letterSpacing: 1,
                            color: "gray",
                        }}
                        className="ion-text-center ion-padding-horizontal"
                    >
                        {t({ id: "forwardSubtitle" })}
                    </p>
                    <IonRow
                        className="ion-align-items-center ion-justify-content-center"
                        style={{ width: "100%" }}
                    >
                        <IonCol sizeXs="12" sizeSm="7">
                            <IonInput
                                value={email}
                                placeholder={t({ id: "forwardInputPlaceholder" })}
                                onIonChange={(e) => setEmail(e.detail.value!)}
                                style={{
                                    borderBottom: "2px solid #eb445a",
                                }}
                            ></IonInput>
                        </IonCol>
                        <IonCol size="auto">
                            <IonButton
                                style={{ width: 120 }}
                                disabled={verified || !email}
                                fill="clear"
                                onClick={() => onStartVerification()}
                            >
                                {verified ? t({ id: "verified" }) : t({ id: "verify" })}
                            </IonButton>
                        </IonCol>
                        {/* <IonCol size="12" className="ion-margin-top ion-text-center">
                            <IonButton
                                size="small"
                                fill="clear"
                                style={{ color: "gray" }}
                                onClick={() => setOpenAdvanced(!openAdvanced)}
                            >
                                {t({ id: "settings" })}
                                <IonIcon slot="end" icon={openAdvanced ? chevronUp : chevronDown} />
                            </IonButton>
                        </IonCol> */}
                    </IonRow>
                    {openAdvanced && (
                        <IonList style={{ maxWidth: 450, margin: "auto" }}>
                            <IonItem
                                lines="none"
                                style={{ borderBottom: "1px solid rgba(0, 0, 0, 0.13)" }}
                            >
                                <IonLabel className="ion-text-wrap ion-letter-spacing-1">
                                    <p>{t({ id: "forwardSkipSubTitle" })}</p>
                                </IonLabel>
                                <IonButton
                                    slot="end"
                                    fill="clear"
                                    onClick={() => setOpenBlacklist(true)}
                                >
                                    <IonIcon slot="icon-only" icon={addOutline} />
                                </IonButton>
                            </IonItem>
                            {blacklist.map((email) => (
                                <IonItem key={email}>
                                    <IonLabel>{email}</IonLabel>
                                    <IonButton
                                        slot="end"
                                        fill="clear"
                                        onClick={() =>
                                            setBlacklist(blacklist.filter((item) => item !== email))
                                        }
                                    >
                                        <IonIcon slot="icon-only" icon={closeCircleOutline} />
                                    </IonButton>
                                </IonItem>
                            ))}
                        </IonList>
                    )}
                </div>
                <div className="ion-padding ion-text-end">
                    <IonButton disabled={!!email && !verified} onClick={() => onSave()}>
                        {t({ id: "save" })}
                    </IonButton>
                </div>
            </IonModal>
            <IonLoading backdropDismiss={false} message={t({ id: "loading" })} isOpen={loading} />
            <IonAlert
                isOpen={openBlacklist}
                onDidDismiss={() => setOpenBlacklist(false)}
                header={t({ id: "forwardAddEmail" })}
                inputs={[
                    {
                        name: "email",
                        type: "email",
                        placeholder: t({ id: "email" }),
                    },
                ]}
                buttons={[
                    {
                        text: t({ id: "cancel" }),
                        role: "cancel",
                        cssClass: "secondary",
                    },
                    {
                        text: t({ id: "add" }),
                        handler: (data) => {
                            setBlacklist([...blacklist, ...[data.email as string]]);
                        },
                    },
                ]}
            />
            <IonAlert
                backdropDismiss={false}
                isOpen={openCode}
                onDidDismiss={() => setOpenCode(false)}
                header={t({ id: "verification" })}
                subHeader={t({ id: "verificationSubtitle" })}
                inputs={[
                    {
                        name: "code",
                        type: "number",
                        placeholder: t({ id: "code" }),
                    },
                ]}
                buttons={[
                    {
                        text: t({ id: "cancel" }),
                        role: "cancel",
                        cssClass: "secondary",
                    },
                    {
                        text: t({ id: "verify" }),
                        handler: (data) => onCompleteVerification(data.code),
                    },
                ]}
            />
        </>
    );
};

export default MailboxRedirectModal;
