import React, { CSSProperties } from "react";
import DOMPurify from "dompurify";

interface TextHtmlProps {
    html: string;
    style?: CSSProperties;
}

const TextHtml: React.FC<TextHtmlProps> = ({ html, style = {} }) => {
    // Add a hook to make all links open a new window
    DOMPurify.addHook("afterSanitizeAttributes", function (node: any) {
        // set all elements owning target to target=_blank
        if ("target" in node) {
            node.setAttribute("target", "_blank");
            // prevent https://www.owasp.org/index.php/Reverse_Tabnabbing
            node.setAttribute("rel", "noopener noreferrer");
        }
        // set non-HTML/MathML links to xlink:show=new
        if (
            !node.hasAttribute("target") &&
            (node.hasAttribute("xlink:href") || node.hasAttribute("href"))
        ) {
            node.setAttribute("xlink:show", "new");
        }
    });

    const clean = DOMPurify.sanitize(html, {
        FORBID_TAGS: ["style"],
    });
    return <div id="text-html" style={style} dangerouslySetInnerHTML={{ __html: clean }}></div>;
};

export default TextHtml;
