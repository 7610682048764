import React from "react";
import { IonAlert } from "@ionic/react";

interface IStatsFindMailboxAlert {
    isOpen: boolean;
    onDismiss: () => void;
    setMailboxId: (mailboxId: string | undefined) => void;
}

const StatsFindMailboxAlert: React.FC<IStatsFindMailboxAlert> = ({
    isOpen,
    onDismiss,
    setMailboxId,
}) => {
    return (
        <IonAlert
            isOpen={isOpen}
            onDidDismiss={() => onDismiss()}
            header={"Find Mailbox"}
            inputs={[
                {
                    name: "mailbox",
                    type: "text",
                    placeholder: "Mailbox ID",
                },
            ]}
            buttons={[
                {
                    text: "Cancel",
                    role: "cancel",
                },
                {
                    text: "Find",
                    handler: ({ mailbox }) => setMailboxId(mailbox),
                },
            ]}
        />
    );
};

export default StatsFindMailboxAlert;
