import React from "react";
import {
    documentOutline,
    readerOutline,
    chatboxEllipsesOutline,
    moonOutline,
    sunnyOutline,
    alarmOutline,
    alertCircle
} from "ionicons/icons";
import { IonList, IonItem, IonIcon, IonLabel, IonMenuToggle } from "@ionic/react";
import { useIntl } from "react-intl";
import { useTheme, IThemes } from "../providers/theme-provider";

interface HomeMenuProps {
    hasExpiredMailboxes: boolean;
}

const HomeMenu: React.FC<HomeMenuProps> = ({ hasExpiredMailboxes }) => {
    const { formatMessage: t } = useIntl();
    const { theme, toggleTheme } = useTheme();
    return (
        <IonMenuToggle>
            <IonList lines="none" style={{ marginTop: 32 }}>
                <IonItem routerLink="/privacy">
                    <IonIcon icon={documentOutline} slot="start" />
                    <IonLabel>{t({ id: "privacy" })}</IonLabel>
                </IonItem>
                <IonItem routerLink="/terms">
                    <IonIcon icon={readerOutline} slot="start" />
                    <IonLabel>{t({ id: "terms" })}</IonLabel>
                </IonItem>
                <IonItem routerLink="/contact">
                    <IonIcon icon={chatboxEllipsesOutline} slot="start" />
                    <IonLabel>{t({ id: "support" })}</IonLabel>
                </IonItem>
                <IonItem onClick={() => toggleTheme()}>
                    <IonIcon
                        icon={theme === IThemes.dark ? sunnyOutline : moonOutline}
                        slot="start"
                    />
                    <IonLabel>{t({ id: "theme" })}</IonLabel>
                </IonItem>
                {hasExpiredMailboxes && (
                    <IonItem routerLink="/expired">
                        <IonIcon icon={alarmOutline} slot="start" />
                        <IonLabel>{t({ id: "expired" })}</IonLabel>
                        <IonIcon color="primary" icon={alertCircle} slot="end" />
                    </IonItem>
                )}
            </IonList>
        </IonMenuToggle>
    );
};

export default HomeMenu;
