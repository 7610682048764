import {
    IonContent,
    IonHeader,
    IonPage,
    IonToolbar,
    IonButtons,
    IonBackButton,
} from "@ionic/react";
import React from "react";
import { useIntl } from "react-intl";

const ContactPage: React.FC = () => {
    const { formatMessage: t } = useIntl();
    return (
        <IonPage>
            <IonHeader className="no-borders">
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonBackButton defaultHref="/" />
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <IonContent className="ion-padding">
                <div style={{ maxWidth: 600, margin: "auto", paddingBottom: 92 }}>
                    <p
                        style={{
                            margin: "64px 0 12px 0",
                            fontSize: 52,
                            fontWeight: "bold",
                        }}
                    >
                        {t({ id: "contactUsTitle" })}
                    </p>
                    <p
                        style={{
                            fontSize: 24,
                            color: "gray",
                            marginBottom: 40,
                        }}
                    >
                        {t({ id: "contactUsSubtitle" })}
                    </p>
                    <a
                        style={{ fontSize: 24, textDecoration: "none" }}
                        href="mailto:contact@mailper.com"
                    >
                        contact@mailper.com
                    </a>
                </div>
            </IonContent>
        </IonPage>
    );
};

export default ContactPage;
