import React, { CSSProperties } from "react";
import emailAddresses from "email-addresses";

interface TextEmailProps {
    email: string;
    onlyEmail?: boolean;
    style?: CSSProperties;
}

const TextEmail: React.FC<TextEmailProps> = ({ email, onlyEmail, style = {} }) => {
    const emailParts = emailAddresses.parseOneAddress(email) as any;
    let formattedEmail = (onlyEmail ? emailParts.address : emailParts.name) || email;
    return (
        <span id="text-email" style={style}>
            {formattedEmail}
        </span>
    );
};

export default TextEmail;
