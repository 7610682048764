import { useEffect, useReducer } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { MailperCommonFirebase, IMailboxMessage } from "@mail-per/common";
import { useFirebase } from "../providers/firebase-provider";

enum UseMailboxMessageActions {
    getMessage,
    setMessage
}

interface IUseMailboxMessageState {
    loading: boolean;
    message: IMailboxMessage | null;
}

const initialState: IUseMailboxMessageState = {
    loading: true,
    message: null
};

const reducer = (state: any, action: any) => {
    switch (action.type) {
        case UseMailboxMessageActions.getMessage:
            return {
                ...state,
                loading: true
            };
        case UseMailboxMessageActions.setMessage:
            return {
                ...state,
                loading: false,
                message: action.payload
            };
        default:
            return state;
    }
};

export const useMailboxMessage = (mailboxId: string, messageId: string) => {
    const { auth, firestore } = useFirebase();
    const [user] = useAuthState(auth);
    const [state, dispatch] = useReducer(reducer, initialState);
    useEffect(() => {
        dispatch({ type: UseMailboxMessageActions.getMessage });
        const unsubscribe = firestore
            .collection(`mailboxes/${mailboxId}/messages`)
            .doc(messageId)
            .onSnapshot(
                docSnapshot => {
                    if (!docSnapshot.exists) {
                        return dispatch({
                            type: UseMailboxMessageActions.setMessage,
                            payload: null
                        });
                    }
                    return dispatch({
                        type: UseMailboxMessageActions.setMessage,
                        payload: MailperCommonFirebase.documentSnapshotToMailboxMessage(docSnapshot)
                    });
                },
                () => {
                    return dispatch({
                        type: UseMailboxMessageActions.setMessage,
                        payload: null
                    });
                }
            );
        return () => {
            unsubscribe();
        };
    }, [firestore, user, mailboxId, messageId]);
    return state as IUseMailboxMessageState;
};
