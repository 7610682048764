import React, { ReactNode, createContext, useContext, useState } from "react";
import { useFirebase } from "./firebase-provider";

const STORAGE_PREFIX = "MAILPER::THEME";

export enum IThemes {
    dark = "dark",
    light = "light",
}

const cssTheme = (theme: IThemes) => {
    document.body.classList.toggle("dark", theme === IThemes.light);
};

const ThemeContext = createContext({} as any);

export const useTheme = () => useContext(ThemeContext);

export const ThemeProvider = ({ children }: { children: ReactNode }) => {
    const { analytics } = useFirebase();

    // Initial Value
    let initialValue = IThemes.light;
    if (window.matchMedia && window.matchMedia("(prefers-color-scheme: dark)").matches) {
        initialValue = IThemes.dark;
    }
    const storedValue = localStorage.getItem(STORAGE_PREFIX) as IThemes;
    if (storedValue) {
        initialValue = storedValue;
    }
    //

    if (initialValue === IThemes.light) {
        cssTheme(IThemes.dark);
    } else {
        cssTheme(IThemes.light);
    }

    const [theme, setTheme] = useState<IThemes>(initialValue as IThemes);

    const toggleTheme = () => {
        if (theme === IThemes.light) {
            setTheme(IThemes.dark);
            cssTheme(IThemes.dark);
            localStorage.setItem(STORAGE_PREFIX, IThemes.dark);
            // Analitycs
            analytics.logEvent("theme", { theme: IThemes.dark });
        } else {
            setTheme(IThemes.light);
            cssTheme(IThemes.light);
            localStorage.setItem(STORAGE_PREFIX, IThemes.light);
            // Analitycs
            analytics.logEvent("theme", { theme: IThemes.light });
        }
    };

    return <ThemeContext.Provider value={{ theme, toggleTheme }}>{children}</ThemeContext.Provider>;
};
