import { useReducer } from "react";
import { useIntl } from "react-intl";
import { IMailbox } from "@mail-per/common";
import { useFirebase } from "../providers/firebase-provider";

enum UseMailboxOperationsActions {
    reset,
    checkMailbox,
    checkMailboxSucess,
    checkMailboxError,
    createMailbox,
    createMailboxSuccess,
    createMailboxError,
}

interface IUseMailboxOperationsState {
    checkLoading: boolean;
    checkSuccess: boolean;
    checkError: string | null;
    createLoading: boolean;
    createSuccess: IMailbox | null;
    createError: string | null;
}

const initialState: IUseMailboxOperationsState = {
    checkLoading: false,
    checkSuccess: false,
    checkError: null,
    createLoading: false,
    createSuccess: null,
    createError: null,
};

const reducer = (state: any, action: any) => {
    switch (action.type) {
        case UseMailboxOperationsActions.reset:
            return initialState;
        case UseMailboxOperationsActions.checkMailbox:
            return {
                ...state,
                checkLoading: true,
            };
        case UseMailboxOperationsActions.checkMailboxSucess:
            return {
                ...state,
                checkLoading: false,
                checkSuccess: true,
                checkError: null,
            };
        case UseMailboxOperationsActions.checkMailboxError:
            return {
                ...state,
                checkLoading: false,
                checkSuccess: false,
                checkError: action.payload,
            };
        case UseMailboxOperationsActions.createMailbox:
            return {
                ...state,
                createLoading: true,
            };
        case UseMailboxOperationsActions.createMailboxSuccess:
            return {
                ...state,
                createLoading: false,
                createSuccess: action.payload,
                createError: null,
            };
        case UseMailboxOperationsActions.createMailboxError:
            return {
                ...state,
                createLoading: false,
                createSuccess: false,
                createError: action.payload,
            };
        default:
            return state;
    }
};

export const useMailboxOperations = () => {
    const { analytics, functions } = useFirebase();
    const { formatMessage: t } = useIntl();
    const [state, dispatch] = useReducer(reducer, initialState);

    const isValid = (email: string) => {
        var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
    };

    const check = async (email: string, domain: string) => {
        // Analitycs
        analytics.logEvent("check_mailbox", { email, domain });
        // Email empty
        if (!email) {
            dispatch({
                type: UseMailboxOperationsActions.reset,
            });
            return;
        }
        // Email too short
        if (email.length < 4) {
            dispatch({
                type: UseMailboxOperationsActions.checkMailboxError,
                payload: `The mailbox ${email + domain} is too short.`,
            });
            return;
        }
        // Email invalid
        if (!isValid(email + domain)) {
            dispatch({
                type: UseMailboxOperationsActions.checkMailboxError,
                payload: `Invalid email format.`,
            });
            return;
        }
        dispatch({
            type: UseMailboxOperationsActions.checkMailbox,
        });
        const checkMailbox = functions.httpsCallable("checkMailbox");
        try {
            const { data: exists } = await checkMailbox({
                email: email + domain,
            });
            if (exists) {
                dispatch({
                    type: UseMailboxOperationsActions.checkMailboxError,
                    payload: t({ id: "mailboxExists" }, { email: email + domain }),
                });
            } else {
                dispatch({
                    type: UseMailboxOperationsActions.checkMailboxSucess,
                });
            }
        } catch {
            dispatch({
                type: UseMailboxOperationsActions.checkMailboxError,
                payload: `Error checking the mailbox ${email + domain}`,
            });
        }
    };

    const create = async (email: string, expiration: number) => {
        // Analitycs
        analytics.logEvent("create_mailbox", { email, expiration });
        //
        dispatch({
            type: UseMailboxOperationsActions.createMailbox,
        });
        const createMailbox = functions.httpsCallable("createMailbox");
        try {
            const { data: mailbox } = await createMailbox({
                email,
                expiration,
            });
            dispatch({
                type: UseMailboxOperationsActions.createMailboxSuccess,
                payload: mailbox,
            });
            return mailbox;
        } catch {
            dispatch({
                type: UseMailboxOperationsActions.createMailboxError,
                payload: `Error creating the mailbox ${email}`,
            });
        }
    };

    return {
        state: state as IUseMailboxOperationsState,
        create,
        check,
    };
};
