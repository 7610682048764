import { useEffect, useReducer } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useFirebase } from "../providers/firebase-provider";

enum UseStatsActions {
    get,
    set,
}

interface IUseStatsState {
    loading: boolean;
    stats: any;
}

const initialState: IUseStatsState = {
    loading: true,
    stats: null,
};

const reducer = (state: any, action: any) => {
    switch (action.type) {
        case UseStatsActions.get:
            return {
                ...state,
                loading: true,
            };
        case UseStatsActions.set:
            return {
                ...state,
                loading: false,
                stats: action.payload,
            };
        default:
            return state;
    }
};

export const UseStats = () => {
    const { auth, firestore } = useFirebase();
    const [user] = useAuthState(auth);
    const [state, dispatch] = useReducer(reducer, initialState);
    useEffect(() => {
        dispatch({ type: UseStatsActions.get });
        const unsubscribe = firestore
            .collection(`system`)
            .doc("stats")
            .onSnapshot(
                (docSnapshot) => {
                    if (!docSnapshot.exists) {
                        return dispatch({
                            type: UseStatsActions.set,
                            payload: null,
                        });
                    }
                    return dispatch({
                        type: UseStatsActions.set,
                        payload: docSnapshot.data(),
                    });
                },
                () => {
                    return dispatch({
                        type: UseStatsActions.set,
                        payload: null,
                    });
                }
            );
        return () => {
            unsubscribe();
        };
    }, [firestore, user]);
    return state as IUseStatsState;
};
